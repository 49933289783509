import { toast } from 'react-toastify';

export const handleChange = (e, dataSetter, errorSetter) => {
    const {id, value} = e.target;
    dataSetter((prevData) => ({
        ...prevData,
        [id]: value,
    }));
    errorSetter((prevData) => ({
        ...prevData,
        [id]: null,
    }));
    if (value === null || value === "") {
        errorSetter((prev) => ({
            ...prev,
            [id]: "*Required",
        }));
    }
}

export const invalidateQueries = (queryClient, queries= []) => {
    queries.map((query) => {
        queryClient.invalidateQueries({
            queryKey: query,
        });
    });
}

export const handleQueryError = (res, errorSetter, t) => {
    if (res?.data?.code) {
        toast.error(res?.data?.description);
    }
    if (res?.data?.data?.code === 1011) {
        errorSetter((prevErrors) => ({
            ...prevErrors,
            username: t(res?.data?.data?.description),
        }));
    } else if (res?.data?.data?.code === 1015) {
        errorSetter((prevErrors) => ({
            ...prevErrors
        }));
    } else if (res?.data?.data?.code === 1014) {
        errorSetter((prevErrors) => ({
            ...prevErrors,
            amount: t(res?.data?.data?.description),
        }));
    } else if (res?.data?.data?.code === 1088) {
        errorSetter((prevErrors) => ({
            ...prevErrors,
            username: t(res?.data?.data?.description),
        }));
    } else if (res?.data?.data?.code) {
        toast.error(res?.data?.data?.description);
    } else {
        toast.error(res?.message);
    }
}
