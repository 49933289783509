import DatePickerComponent from '../../Common/DatePickerComponent';
import { PhoneInput } from 'react-international-phone';
import Select from 'react-select';
import { validateAge } from '../../../Validator/register';
import React from 'react';
import { useTranslation } from "react-i18next";

export const ShopRegisterFormStep2 = ({data, activeStep, errors, register, trigger, checkEmailField, handleEmailCheck, selectedDob, handleDateChange, isCalenderOpen, openCalender, closeCalender, phoneNumber, handlePhoneNumber, genderOptions, genderChange, selectedGender, selectedCountry, changeCountry, selectedState, changeState, handleCustomField, states, handleBack, countryList, stateList, handleCountry, handleNext }) => {
    const {t} = useTranslation();

    return <div className="row row_top form-business">
        <div className="regsiter_step_1_view_left_sec_head">
            <p>{t(data?.registrationSteps[activeStep - 2]?.label)}</p>
        </div>
        <div className="regsiter_second_step_bx">
            <div className="row">
                {data?.contactInformation?.contactField?.map((item, index) => (
                    <div className="col-md-6" key={index}>
                        <div className="regsiter_form_box">
                            <label htmlFor={item.code}>
                                {t(`${item.code}`)}
                                <span className="text-danger" hidden={!item.required}>
                          ٭
                        </span>
                            </label>
                            {item.type === "text" &&
                                item.code !== "state" &&
                                !item.options && (
                                    <input
                                        id={item.code}
                                        name={item.code}
                                        type="text"
                                        className={`form-control ${
                                            errors[item.code] ? "error-field" : ""
                                        }`}
                                        placeholder={item.placeholder}
                                        {...register(item.code, {
                                            required: {
                                                value: item.required,
                                                message: t("this_field_is_required"),
                                            },
                                            // pattern: {
                                            //   value: /^[A-Za-z0-9]+$/,
                                            //   message: t("invalid_format"),
                                            // },
                                        })}
                                        onBlur={async () => await trigger(item.code)}
                                    />
                                )}
                            {item.type === "email" && (
                                <>
                                    <input
                                        id={item.code}
                                        name={item.code}
                                        type="email"
                                        className={`form-control ${
                                            (!checkEmailField.data?.data?.status &&
                                                checkEmailField.data?.data?.field === "email" &&
                                                checkEmailField.data?.data?.code === 1117) ||
                                            errors[item.code]
                                                ? "error-field"
                                                : ""
                                        }`}
                                        placeholder={item.placeholder}
                                        {...register(item.code, {
                                            required: {
                                                value: item.required,
                                                message: t("this_field_is_required"),
                                            },
                                            pattern: {
                                                value:
                                                    /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]+$/,
                                                message: t("invalid_email_format"),
                                            },
                                        })}
                                        onBlur={async () => await trigger(item.code)}
                                        onChangeCapture={(e) => {
                                            handleEmailCheck(e.target);
                                        }}
                                    />
                                    {!checkEmailField.data?.data?.status &&
                                        checkEmailField.data?.data?.field === "email" &&
                                        checkEmailField.data?.data?.code === 1117 && (
                                            <span className="error-message-validator">
                                {t("email_exists")}
                              </span>
                                        )}
                                </>
                            )}
                            {item.type === "date" && (
                                <DatePickerComponent
                                    className={`date-picker ${
                                        errors[item.code] ? "error-field" : ""
                                    }`}
                                    date={selectedDob}
                                    handleDateChange={(newDate) =>
                                        handleDateChange(newDate, item)
                                    }
                                    isCalenderOpen={isCalenderOpen}
                                    openCalender={openCalender}
                                    closeCalender={closeCalender}
                                />
                            )}
                            {item.type === "number" && item.code === "mobile" && (
                                <PhoneInput
                                    defaultCountry="us"
                                    value={phoneNumber}
                                    onChange={handlePhoneNumber}
                                />
                            )}
                            {item.type === "number" && !(item.code === "mobile") && (
                                <input
                                    id={item.code}
                                    name={item.code}
                                    type="number"
                                    className={`form-control ${
                                        errors[item.code] ? "error-field" : ""
                                    }`}
                                    placeholder={item.placeholder}
                                    {...register(item.code, {
                                        required: {
                                            value: item.required,
                                            message: t("this_field_is_required"),
                                        },
                                        minLength: {
                                            value: 5,
                                            message: t("min_length_of_5"),
                                        },
                                    })}
                                    onBlur={async () => await trigger(item.code)}
                                />
                            )}

                            {item.type === "text" && item.code === "gender" && (
                                <Select
                                    id={item.code}
                                    name={item?.code}
                                    className={`dropdown-common ${
                                        errors[item.code] ? "error-field" : ""
                                    }`}
                                    {...register(item.code, {
                                        required: {
                                            value: item.required,
                                            message: t("this_field_is_required"),
                                        },
                                    })}
                                    onBlur={async () => await trigger(item.code)}
                                    options={genderOptions(item?.options)}
                                    onChange={genderChange}
                                    value={selectedGender}
                                    isSearchable={false}
                                />
                            )}
                            {item.type === "text" && item.code === "country" && (
                                <Select
                                    isSearchable={false}
                                    id={item.code}
                                    name={item?.code}
                                    className={` ${
                                        errors[item.code] ? "error-field" : ""
                                    }`}
                                    {...register(item.code, {
                                        required: {
                                            value: item.required,
                                            message: t("this_field_is_required"),
                                        },
                                    })}
                                    onBlur={async () => await trigger(item.code)}
                                    value={countryList(item?.options).find(
                                        (item) => item.value === selectedCountry
                                    )}
                                    options={countryList(item?.options)}
                                    onChange={changeCountry}
                                />
                            )}
                            {item.type === "text" && item.code === "state" && (
                                <Select
                                    isSearchable={false}
                                    id={item.code}
                                    name={item?.code}
                                    className={` ${
                                        errors[item.code] ? "error-field" : ""
                                    }`}
                                    {...register(item.code, {
                                        required: {
                                            value: item.required,
                                            message: t("this_field_is_required"),
                                        },
                                    })}
                                    options={stateList(item?.options)}
                                    onBlur={async () => await trigger(item.code)}
                                    value={stateList(item?.options).find(
                                        (state) => state?.value === selectedState
                                    )}
                                    onChange={changeState}
                                />
                            )}
                            {errors[item.code] && (
                                <span className="error-message-validator">
                          {errors[item.code].message}
                        </span>
                            )}
                        </div>
                    </div>
                ))}
                {data?.contactInformation?.customField?.map((item, index) => (
                    <div className="col-md-6" key={index}>
                        <div className="regsiter_form_box">
                            <label htmlFor={item.code}>
                                {item.value}{" "}
                                <span className="text-danger" hidden={!item.required}>
                          ٭
                        </span>
                            </label>
                            {item.type === "text" && !item.options && (
                                <input
                                    id={item.code}
                                    name={item.code}
                                    type="text"
                                    className={`form-control ${
                                        errors[item.code] ? "error-field" : ""
                                    }`}
                                    placeholder={item.placeholder}
                                    {...register(item.code, {
                                        required: {
                                            value: item.required,
                                            message: t("this_field_is_required"),
                                        },
                                        // pattern: {
                                        //   value: /^[A-Za-z0-9]+$/,
                                        //   message: t("invalid_format"),
                                        // },
                                    })}
                                    onChange={async (e) => {
                                        const inputValue = e.target.value; // Get the current value of the input field
                                        handleCustomField(item.id, inputValue);
                                        await trigger(item.code);
                                    }}
                                />
                            )}
                            {item.type === "email" && (
                                <>
                                    <input
                                        id={item.code}
                                        name={item.code}
                                        type="email"
                                        className={`form-control ${
                                            (!checkEmailField.data?.data?.status &&
                                                checkEmailField.data?.data?.field === "email" &&
                                                checkEmailField.data?.data?.code === 1117) ||
                                            errors[item.code]
                                                ? "error-field"
                                                : ""
                                        }`}
                                        placeholder={item.placeholder}
                                        {...register(item.code, {
                                            required: {
                                                value: item.required,
                                                message: t("this_field_is_required"),
                                            },
                                            pattern: {
                                                value:
                                                    /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]+$/,
                                                message: t("invalid_email_format"),
                                            },
                                        })}
                                        onChangeCapture={async (e) => {
                                            const inputValue = e.target.value; // Get the current value of the input field
                                            handleEmailCheck(e.target);
                                            handleCustomField(item.id, inputValue);
                                        }}
                                    />
                                    {!checkEmailField.data?.data?.status &&
                                        checkEmailField.data?.data?.field === "email" &&
                                        checkEmailField.data?.data?.code === 1117 && (
                                            <span className="error-message-validator">
                                {t("email_exists")}
                              </span>
                                        )}
                                </>
                            )}
                            {item.type === "date" && (
                                <>
                                    <input
                                        id={item.code}
                                        name={item.code}
                                        type="date"
                                        className={`form-control ${
                                            errors[item.code] ? "error-field" : ""
                                        }`}
                                        placeholder={item.placeholder}
                                        {...register(item.code, {
                                            required: {
                                                value: item.required,
                                                message: t("this_field_is_required"),
                                            },
                                            validate: (value) => validateAge(value, t),
                                        })}
                                        onChange={async (e) => {
                                            const inputValue = e.target.value; // Get the current value of the input field
                                            handleCustomField(item.id, inputValue);
                                            await trigger(item.code);
                                        }}
                                    />
                                    {errors.date_of_birth && (
                                        <span className="error-message-validator">
                              {errors.date_of_birth.message}
                            </span>
                                    )}
                                </>
                            )}
                            {item.type === "textarea" && (
                                <textarea
                                    id={item.code}
                                    name={item.code}
                                    className={`form-control ${
                                        errors[item.code] ? "error-field" : ""
                                    }`}
                                    placeholder={item.placeholder}
                                    {...register(item.code, {
                                        required: {
                                            value: item.required,
                                            message: t("this_field_is_required"),
                                        },
                                    })}
                                    onChange={async (e) => {
                                        const inputValue = e.target.value; // Get the current value of the input field
                                        handleCustomField(item.id, inputValue);
                                        await trigger(item.code);
                                    }}
                                />
                            )}
                            {item.type === "number" && (
                                <input
                                    id={item.code}
                                    name={item.code}
                                    type="number"
                                    className={`form-control ${
                                        errors[item.code] ? "error-field" : ""
                                    }`}
                                    placeholder={item.placeholder}
                                    {...register(item.code, {
                                        required: {
                                            value: item.required,
                                            message: t("this_field_is_required"),
                                        },
                                        minLength: {
                                            value: 5,
                                            message: t("min_length_of_5"),
                                        },
                                    })}
                                    onChange={async (e) => {
                                        const inputValue = e.target.value; // Get the current value of the input field
                                        handleCustomField(item.id, inputValue);
                                        await trigger(item.code);
                                    }}
                                />
                            )}
                            {item.type === "text" && item.code === "gender" && (
                                <select
                                    id={item.code}
                                    name={item.code}
                                    className={`form-control ${
                                        errors[item.code] ? "error-field" : ""
                                    }`}
                                    {...register(item.code, {
                                        required: {
                                            value: item.required,
                                            message: t("this_field_is_required"),
                                        },
                                    })}
                                    onChange={async (e) => {
                                        const inputValue = e.target.value; // Get the current value of the input field
                                        handleCustomField(item.id, inputValue);
                                        await trigger(item.code);
                                    }}
                                >
                                    {item?.options?.map((option, optionIndex) => (
                                        <option key={optionIndex} value={option.value}>
                                            {option.title}
                                        </option>
                                    ))}
                                </select>
                            )}
                            {item.type === "text" && item.code === "country" && (
                                <select
                                    id={item.code}
                                    name={item.code}
                                    className={`form-control ${
                                        errors[item.name] ? "error-field" : ""
                                    }`}
                                    {...register(item.code, {
                                        required: {
                                            value: item.required,
                                            message: t("this_field_is_required"),
                                        },
                                    })}
                                    onChange={async (e) => {
                                        const inputValue = e.target.value; // Get the current value of the input field
                                        handleCountry(e.target.value, item.options);
                                        handleCustomField(item.id, inputValue);
                                        await trigger(item.code);
                                    }}
                                >
                                    <option>{t("select_the_country")}</option>
                                    {item?.options?.map((option, optionIndex) => (
                                        <option key={optionIndex} value={option.id}>
                                            {option.name}
                                        </option>
                                    ))}
                                </select>
                            )}
                            {item.type === "text" && item.code === "state" && (
                                <select
                                    id={item.code}
                                    name={item.name}
                                    className={`form-control ${
                                        errors[item.name] ? "error-field" : ""
                                    }`}
                                    {...register(item.code, {
                                        required: {
                                            value: item.required,
                                            message: t("this_field_is_required"),
                                        },
                                    })}
                                    onChange={async (e) => {
                                        const inputValue = e.target.value; // Get the current value of the input field
                                        handleCustomField(item.id, inputValue);
                                        await trigger(item.code);
                                    }}
                                >
                                    {states?.map((option, optionIndex) => (
                                        <option key={optionIndex} value={option.id}>
                                            {option.name}
                                        </option>
                                    ))}
                                </select>
                            )}
                            {errors[item.code] && (
                                <span className="error-message-validator">
                          {errors[item.code].message}
                        </span>
                            )}
                        </div>
                    </div>
                ))}
                <div className="col-md-12">
                    <button
                        type="button"
                        className="btn btn-dark text-white float-start back mt-4 rounded-3"
                        onClick={handleBack}
                    >
                        {t("back")}
                    </button>
                    <button
                        type="button"
                        className="btn text-white float-end next mt-4 rounded-3 bg-color-info"
                        onClick={handleNext}
                    >
                        {t("next")}
                    </button>
                </div>
            </div>
        </div>
    </div>
}
