import React from 'react';
import { useTranslation } from "react-i18next";
import { loginPasswordValidator, loginUsernameValidator, passwordRules } from '../../../Validator/register';
import InputAdornment from '@mui/material/InputAdornment';
import IconButton from '@mui/material/IconButton';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import PasswordChecklist from 'react-password-checklist';
import HCaptcha from '@hcaptcha/react-hcaptcha';
import { SITEKEY } from '../../../config/config';

export const ShopRegisterFormStep3 = ({ data, activeStep, checkUsernameField, errors, register, handleUsernameCheck, trigger, showPassword, handleShowPassword, watch, getValues,
                                          handleToggleRegisterModal, formValues, onLoad, setToken, captchaRef, handleBack, handleNext }) => {
    const {t} = useTranslation();
    return <div className="row row_top justify-content-center form-business">
        <div className="regsiter_step_1_view_left_sec_head">
            {data?.registrationSteps &&
                (data?.regPack.length === 0 && data?.regData === 0
                    ? t(data?.registrationSteps[activeStep - 2]?.label)
                    : data?.regPack.length === 0 && data?.regData !== 0
                        ? t(data?.registrationSteps[activeStep - 1]?.label)
                        : t(data?.registrationSteps[activeStep - 1]?.label))}
        </div>
        <div className="regsiter_second_step_bx">
            <div className="row">
                {data?.loginInformation.map((item, index) => (
                    <div className="col-md-12" key={index}>
                        <div className="regsiter_form_box">
                            <label htmlFor={item.code}>
                                {t(item.code)} <span className="text-danger">٭</span>
                            </label>
                            {item.type === "text" && (
                                <>
                                    <input
                                        id={item.code}
                                        name={item.code}
                                        type={item.type}
                                        className={`form-control ${
                                            (!checkUsernameField.data?.data?.status &&
                                                checkUsernameField.data?.data?.field ===
                                                "username" &&
                                                checkUsernameField.data?.data?.code === 1117) ||
                                            errors[item.code]
                                                ? "error-field"
                                                : ""
                                        }`}
                                        placeholder={item.placeholder}
                                        {...register(
                                            item.code,
                                            loginUsernameValidator(item, t)
                                        )}
                                        onChangeCapture={async (e) => {
                                            await handleUsernameCheck(e.target);
                                        }}
                                        onBlur={async () => await trigger(item.code)}
                                    />
                                    {!checkUsernameField.data?.data?.status &&
                                        checkUsernameField.data?.data?.field ===
                                        "username" &&
                                        checkUsernameField.data?.data?.code === 1117 && (
                                            <span className="error-message-validator">
                                {t("username_exists")}
                              </span>
                                        )}
                                </>
                            )}
                            {item.type === "password" && (
                                <>
                                    <div style={{position: "relative"}}>
                                        <input
                                            id={item.code}
                                            name={item.code}
                                            type={showPassword ? "text" : "password"}
                                            style={{marginBottom: "8px"}}
                                            className={`form-control ${
                                                errors[item.code] ? "error-field" : ""
                                            }`}
                                            placeholder={item.placeholder}
                                            {...register(
                                                item.code,
                                                loginPasswordValidator(item, t)
                                            )}
                                            onBlur={async () => await trigger(item.code)}
                                        />
                                        <InputAdornment
                                            position="end"
                                            style={{
                                                position: "absolute",
                                                right: 5,
                                                top: 40,
                                            }}
                                        >
                                            <IconButton
                                                onClick={handleShowPassword}
                                                onMouseDown={(e) => e.preventDefault()}
                                            >
                                                {showPassword ? (
                                                    <Visibility/>
                                                ) : (
                                                    <VisibilityOff/>
                                                )}
                                            </IconButton>
                                        </InputAdornment>
                                    </div>
                                    {watch("password", "") && 
                                        <PasswordChecklist
                                        rules={passwordRules(item.validation)}
                                        minLength={item.validation.minLength}
                                        value={watch("password", "")}
                                        className="mb-3"
                                        />
                                    }
                                    <label htmlFor={"confirmPassword"}>
                                        {t("confirmPassword")}{" "}
                                        <span className="text-danger">٭</span>
                                    </label>
                                    <input
                                        id={"confirmPassword"}
                                        name={"confirmPassword"}
                                        type={"password"}
                                        style={{marginBottom: "8px"}}
                                        className={`form-control ${
                                            errors["confirmPassword"] ? "error-field" : ""
                                        }`}
                                        placeholder={item.placeholder}
                                        {...register("confirmPassword", {
                                            required: {
                                                message: t("this_field_is_required"),
                                            },
                                            validate: (value) =>
                                                value === getValues("password") ||
                                                t("passwordNotMatch"),
                                        })}
                                        onBlur={async () =>
                                            await trigger("confirmPassword")
                                        }
                                    />
                                </>
                            )}
                            {errors[item.code] && (
                                <span className="error-message-validator">
                          {t(errors[item.code].message)}
                        </span>
                            )}
                            {errors["confirmPassword"] &&
                                item.code !== "username" && (
                                    <span className="error-message-validator">
                            {errors["confirmPassword"].message}
                          </span>
                                )}
                        </div>
                    </div>
                ))}
                <div className="col-md-12">
                    <div className="regsiter_form_box_tandc">
                        <label className="d-flex" htmlFor="box">
                            <input
                                name={"termsAndCondition"}
                                type="checkbox"
                                id="box"
                                style={{marginRight: "5px"}}
                                {...register("termsAndCondition", {
                                    required: true,
                                })}
                            />
                        </label>
                        <a
                            data-bs-toggle="modal"
                            className="pop_terms_btn"
                            onClick={handleToggleRegisterModal}
                        >
                            {t("iAcceptTermsAndConditions")}
                            <span className="text-danger"> ٭ </span>
                        </a>
                        {errors["termsAndCondition"] &&
                            formValues["termsAndCondition"] === false && (
                                <span className="error-message-validator">
                          *{t("required")}
                        </span>
                            )}
                    </div>
                </div>
                <div className="col-md-12">
                    <div className="regsiter_form_box_tandc">
                        <HCaptcha
                            sitekey={SITEKEY}
                            onLoad={onLoad}
                            onVerify={setToken}
                            ref={captchaRef}
                        />
                        {errors["token"] && (
                            <span className="error-message-validator">
                        *{t("required")}
                      </span>
                        )}
                    </div>
                </div>
                <div className="col-md-12">
                    <button
                        type="button"
                        className="btn btn-dark text-white float-start back mt-4 rounded-3"
                        onClick={handleBack}
                    >
                        {t("back")}
                    </button>
                    <button
                        type="button"
                        className="btn text-white float-end next mt-4 rounded-3 bg-color-info"
                        onClick={handleNext}
                    >
                        {t("next")}
                    </button>
                </div>
            </div>
        </div>
    </div>

}
