import React, { useState, useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

const OptionsButtton = ({
                          title,
                          handleOpen,
                          link,
}) => {
  const { t } = useTranslation();


  return (
    <>
      <div className="btn-group">
        { handleOpen && <Link className="top_righ_pop_btn" aria-controls="ewalletTrnsfer" onClick={handleOpen}>{t(title)}</Link> }
        { link && <Link className="top_righ_pop_btn" aria-controls="ewalletTrnsfer" to={link}>{t(title)}</Link> }
      </div>
    </>
  );
};

export default OptionsButtton;
