import React, { useState } from "react";
import { Offcanvas, Form } from "react-bootstrap";
import { handleChange, invalidateQueries, handleQueryError } from '../walletHelper';
import { getWalletConfig } from '../walletConfigs';
import { useQueryClient } from "@tanstack/react-query";
import SubmitButton from "../../Common/buttons/SubmitButton";
import { useTranslation } from "react-i18next";
import CurrencyInput from "react-currency-input-field";
import { WalletAmountInput } from './WalletAmountInput';

export const WalletSwapOffCanvas = ({ balance, show, handleClose, fromWallet, toWallet }) => {
  const { t } = useTranslation();
  const [transferData, setTransferData] = useState({ amount: ""});
  const [errorMessage, setErrorMessage] = useState({ amount: null });
  const queryClient = useQueryClient();
  const walletConfig = getWalletConfig(fromWallet);

  const onHandleChange = (e) => {
    console.log('setTransferData', setTransferData);
    console.log('setErrorMessage', setErrorMessage);
    return handleChange(e, setTransferData, setErrorMessage);
  }

  const swapMutation = walletConfig.swapToUSXHook();
  const isFormValid = () => {
    return (
      transferData?.amount > 0
    );
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!isFormValid()) {
      return;
    }
    try {
      const postData = { amount: transferData?.amount };
      await swapMutation.mutateAsync(postData, {
        onSuccess: (res) => {
          if (res.status === 200) {
            setErrorMessage({ amount: null });
            setTransferData({ amount: "" });
            invalidateQueries(queryClient, walletConfig.queries);
            handleClose(false);
          } else {
            handleQueryError(res, setErrorMessage, t);
          }
        },
      });
    } catch (error) {
      // Handle general error or network issue
      console.error("Error submitting transfer:", error);
    }
  };

  return (
    <Offcanvas
      show={show}
      onHide={() => {
        handleClose();
        setTransferData({ amount: "" });
      }}
      placement="end"
      id="ewalletTrnsfer"
    >
      <Offcanvas.Header closeButton>
        <Offcanvas.Title>{`${t("swap")} ${t(fromWallet)} ${t("to")} ${t(toWallet)} `}</Offcanvas.Title>
      </Offcanvas.Header>
      <Offcanvas.Body>
        <Form>
          <Form.Group className="mb-3">
            <Form.Label>
              {t("amount")}
              <span>*</span>
            </Form.Label>
            <WalletAmountInput fieldName={`amount`} wallet={fromWallet} fieldValue={transferData.amount} errorMessage={errorMessage} onHandleChange={onHandleChange} t={t}></WalletAmountInput>
          </Form.Group>
          <Form.Group className="mb-3">
            <Form.Label>{t("availableAmount")}</Form.Label>
            <WalletAmountInput fieldName={``} wallet={fromWallet} fieldValue={balance} t={t} disabled={true}></WalletAmountInput>
          </Form.Group>
          <SubmitButton
            isSubmitting={!isFormValid() || swapMutation.isLoading}
            click={handleSubmit}
            text="submit"
            className={"btn btn-primary submit_btn"}
            isLoading={swapMutation.isLoading}
          />
        </Form>
      </Offcanvas.Body>
    </Offcanvas>
  );
};
