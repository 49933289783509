import React, { useState, useRef, useEffect } from "react";
import Input from "./FormInput.jsx";
import logo_user from "../../assests/images/logo_user.png";
import welcomeImg from "../../assests/images/welcomeImg.svg";
import SubmitButton from "../Common/buttons/SubmitButton.jsx";
import IconButton from "@mui/material/IconButton";
import Visibility from "@mui/icons-material/Visibility";
import InputAdornment from "@mui/material/InputAdornment";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import { ApiHook } from "../../hooks/apiHook.js";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";
// import HCaptcha from "@hcaptcha/react-hcaptcha";
import ReCAPTCHA from "react-google-recaptcha";
// import { SITEKEY } from "../../config/config";
import { useNavigate } from "react-router";
import { useTranslation } from "react-i18next";
import otherBackoffice from "../../utils/otherBackoffice.ts";
import API from "../../api/api.js";
import { CAPTCHA_SITEKEY, IS_DEV } from "../../config/config.js";

const LoginForm = ({ params, selectedPage, setSelectedPage }) => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const companyDetails = useSelector((state) => state.replica?.companyDetails);
  const favicon = document.getElementById("dynamic-favicon");
  if (favicon) {
    favicon.href = companyDetails?.favicon;
  }
  const [credentials, setCredentials] = useState({
    username: params.username ?? "",
    password: params.username ? "12345678" : "",
  });

  const [errorMessage, setErrorMessage] = useState({
    username: null,
    password: null,
    userCredentials: null,
    token: null,
    reToken: null,
    rePassword: null,
  });

  const [showPassword, setShowPassword] = useState(false);
  // const [selectedPage, setSelectedPage] = useState("login");
  const [username, setUsername] = useState("");
  const isSubmittingRef = useRef(false);
  const [changePassword, setChangePassword] = useState(false);
  const loginMutation = ApiHook.CallLoginUser();
  const verifyOtp = ApiHook.Call2faVerifyOtp();
  const forgotPasswordMutation = ApiHook.CallForgotPassword();
  const changePasswordMutation = ApiHook.CallChangePassword();
  const registerMutation = ApiHook.CallShopRegisterUser();

  const [submitting, setSubmitting] = useState(false);

  const [otp, setotp] = useState("");
  const { isEnabled, twoFadata } = useSelector(
    (state) => state?.user?.twoFaAuth
  );
  const [token, setToken] = useState(null);
  const captchaRef = useRef(null);
  const handleShowPassword = () => {
    setShowPassword(!showPassword);
  };
  const handleForgotUsername = (event) => {
    const { value } = event.target;
    setUsername(value);
  };

  const handlePasswordUpdates = (e) => {
    const { name, value } = e.target;
    setPasswordUpdates((prev) => ({
      ...prev,
      [name]: value,
    }));
  };
  const [passwordUpdates, setPasswordUpdates] = useState({
    token: null,
    newPassword: null,
  });

  const handleChange = (event) => {
    const { name, value } = event.target;
    setCredentials((prevCredentials) => ({
      ...prevCredentials,
      [name]: value,
    }));
    setErrorMessage((prev) => ({
      ...prev,
      [name]: null,
    }));

    setErrorMessage((prev) => ({
      ...prev,
      userCredentials: null,
    }));

    if (value === null || value === "") {
      setErrorMessage((prev) => ({
        ...prev,
        [name]: "*Required",
      }));
    }
  };

  const isFormValid = () => {
    return (
      credentials?.password.trim() !== "" && credentials?.username.trim() !== ""
    );
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (!token) {
      return setErrorMessage({
        userCredentials: "Please verify captcha",
      });
    }

    if (!isSubmittingRef.current) {
      isSubmittingRef.current = true;
      setSubmitting(true);

      try {
        const loginInOldBackofficeRequest = await otherBackoffice.login(
          credentials.username,
          credentials.password,
          token
        );
        const { email, firstname, lastname, userName, distributorId } =
          loginInOldBackofficeRequest.data;
        localStorage.setItem("distributorId", distributorId);

        const bid = Math.floor(Math.random() * 9000000000) + 1000000000;

        const shopRegister = await API.post("shop-register", {
          confirmPassword: credentials.password,
          country: 1,
          date_of_birth: "1912-12-12",
          email,
          first_name: firstname,
          gender: "M",
          last_name: lastname,
          mobile: "+1234567890",
          password: credentials.password,
          paymentType: "3",
          position: null,
          regFromTree: 0,
          state: null,
          termsAndCondition: true,
          token,
          // username: email.split("@")[0],
          username: userName,
          // bid,
        });
        if (shopRegister?.status === 500) {
          // User already exists
          console.log("User already exists in our db");
        }

        loginMutation.mutateAsync({
          username: userName,
          password: credentials.password,
        });
      } catch (error) {
        setErrorMessage({
          // userCredentials: error?.response?.data?.message,
          userCredentials: "Invalid credentials",
        });
        isSubmittingRef.current = false;
      } finally {
        setSubmitting(false);
      }
      isSubmittingRef.current = false;
      return;

      const request = await loginMutation.mutateAsync(credentials, {
        onSuccess: (res) => {
          console.log("onSuccess", res, "Renys");
          if (res?.code === 1003) {
            setErrorMessage({
              userCredentials: res?.data,
            });
          } else if (res?.code === 1037) {
            setErrorMessage({
              userCredentials: res?.data,
            });
          } else {
            setErrorMessage({
              userCredentials: res?.data,
            });
          }
        },
      });

      isSubmittingRef.current = false;

      if (request.data?.data?.accessToken) {
        otherBackoffice.login(
          credentials.username,
          credentials.password,
          token
        );
      }
    }
  };

  const resetPassword = async (event) => {
    setChangePassword(false);
    event.preventDefault();
    console.log(token, "token");
    if (!token) {
      setErrorMessage((prev) => ({
        ...prev,
        ["token"]: "*Required",
      }));
    } else {
      setErrorMessage((prev) => ({
        ...prev,
        ["token"]: null,
      }));
    }

    if (!username) {
      setErrorMessage((prev) => ({
        ...prev,
        ["username"]: "*Required",
      }));
      return;
    }
    if (!isSubmittingRef.current) {
      isSubmittingRef.current = true;
      const data = {
        username: username,
        captchaToken: token,
      };
      await forgotPasswordMutation.mutateAsync(data, {
        onSuccess: (res) => {
          if (res?.status) {
            // setChangePassword(true);
            toast.success(res?.data);
            isSubmittingRef.current = false;
          } else {
            toast.error(res?.description);
            isSubmittingRef.current = false;
          }
        },
      });
    }
  };

  const updatePassword = async (event) => {
    event.preventDefault();
    if (!passwordUpdates.token) {
      setErrorMessage((prev) => ({
        ...prev,
        ["reToken"]: "*Required",
      }));
    } else if (!passwordUpdates.newPassword) {
      setErrorMessage((prev) => ({
        ...prev,
        ["rePassword"]: "*Required",
      }));
    }
    const data = {
      username: username,
      token: passwordUpdates.token,
      newPassword: passwordUpdates.newPassword,
    };
    await changePasswordMutation.mutateAsync(data, {
      onSuccess: (res) => {
        if (res?.status) {
          setChangePassword(false);
          toast.success(res?.data);
          isSubmittingRef.current = false;
        } else {
          toast.error(res?.description);
          isSubmittingRef.current = false;
        }
      },
    });
  };

  const handleOtpChange = (e) => {
    const { value } = e.target;
    setotp(value);
  };

  const handleVerifyotp = async (e) => {
    e.preventDefault();
    try {
      await verifyOtp.mutateAsync(
        {
          admin_user_name: credentials.admin_user_name,
          username: credentials.username,
          password: credentials.password,
          secretKey: twoFadata?.secretKey,
          otp: otp,
        },
        {
          onSuccess: (res) => {
            if (res?.code === 1132) {
              setErrorMessage({
                userCredentials: res?.data,
              });
            } else if (res?.code === 1037) {
              setErrorMessage({
                userCredentials: res?.data,
              });
            } else {
              setErrorMessage({
                userCredentials: res?.data,
              });
            }
          },
        }
      );
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <>
      <div className="col-md-12 logincredDetail">
        <div className="loginFormSec w-100 p-15">
          <div className="loginLogo">
            <img src={"/img/logos/sellex-logo.jpeg"} alt="" />
          </div>
          {selectedPage === "login" ? (
            <>
              {
                //2fa
                isEnabled && isEnabled ? (
                  <div>
                    {errorMessage?.userCredentials && (
                      <div style={{ color: "red", textAlign: "center" }}>
                        {errorMessage?.userCredentials}
                      </div>
                    )}
                    {twoFadata?.showQr && (
                      <div className="qrcode-container">
                        <img src={twoFadata?.authQrCode} alt="qr code" />
                      </div>
                    )}
                    <form onSubmit={handleVerifyotp}>
                      <Input
                        type="text"
                        id="admin"
                        name="admin_user_name"
                        placeholder="OTP"
                        value={otp}
                        onChange={handleOtpChange}
                      />
                    </form>

                    <div className="loginBtn">
                      <SubmitButton
                        isSubmitting={verifyOtp?.isLoading}
                        click={handleVerifyotp}
                        text={verifyOtp.isLoading ? "Processing..." : "Verify"}
                        className={"btn"}
                      />
                    </div>
                  </div>
                ) : (
                  <form onSubmit={handleSubmit}>
                    {errorMessage?.userCredentials && (
                      <div style={{ color: "red", textAlign: "center" }}>
                        {errorMessage?.userCredentials}
                      </div>
                    )}
                    <Input
                      type="text"
                      id="fname"
                      name="username"
                      placeholder="Email"
                      value={credentials.username}
                      onChange={handleChange}
                    />
                    {errorMessage?.username && (
                      <div style={{ color: "red" }}>
                        {errorMessage?.username}
                      </div>
                    )}
                    <div className="LoginPasswordField">
                      <Input
                        type={showPassword ? "text" : "password"}
                        id="password"
                        name="password"
                        placeholder="Password"
                        value={credentials.password}
                        onChange={handleChange}
                      />
                      <InputAdornment
                        position="end"
                        style={{ position: "absolute", right: 0, top: 32 }}
                      >
                        <IconButton
                          onClick={handleShowPassword}
                          onMouseDown={(e) => e.preventDefault()}
                        >
                          {showPassword ? <Visibility /> : <VisibilityOff />}
                        </IconButton>
                      </InputAdornment>
                    </div>
                    {errorMessage?.password && (
                      <div style={{ color: "red" }}>
                        {errorMessage?.password}
                      </div>
                    )}
                    {/* <a
                      className="forgetPassword"
                      onClick={() => setSelectedPage("resetPassword")}
                    >
                      Forgot Password?
                    </a> */}
                    {/* <HCaptcha
                      sitekey={SITEKEY}
                      onVerify={setToken}
                      ref={captchaRef}
                    /> */}
                    <ReCAPTCHA
                      sitekey={CAPTCHA_SITEKEY}
                      onChange={setToken}
                    />
                    <div className="loginBtn">
                      <SubmitButton
                        isSubmitting={submitting}
                        click={handleSubmit}
                        text={
                          submitting ? "Submitting..." : "Login"
                        }
                        className={"btn"}
                      />
                    </div>

                    {/* <button
                      type="button"
                      onClick={() => navigate("/register")}
                      className="border-2 border-solid border-[#645a88] text-[#645a88] font-bold py-2 px-4 rounded w-full mt-2"
                    >
                      {t("register")}
                    </button> */}
                  </form>
                )
              }
            </>
          ) : changePassword ? (
            <div className="passwordInput">
              <form onSubmit={updatePassword}>
                <Input
                  type="text"
                  id="token"
                  name="token"
                  placeholder="Token"
                  value={passwordUpdates.token}
                  onChange={handlePasswordUpdates}
                />
                {errorMessage?.reToken && (
                  <div style={{ color: "red" }}>{errorMessage?.reToken}</div>
                )}
                <div className="LoginPasswordField">
                  <Input
                    type={showPassword ? "text" : "password"}
                    id="password"
                    name="newPassword"
                    placeholder="Password"
                    value={passwordUpdates.newPassword}
                    onChange={handlePasswordUpdates}
                  />
                  <InputAdornment
                    position="end"
                    style={{ position: "absolute", right: 0, top: 32 }}
                  >
                    <IconButton
                      onClick={handleShowPassword}
                      onMouseDown={(e) => e.preventDefault()}
                    >
                      {showPassword ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                  </InputAdornment>
                </div>
                {errorMessage?.rePassword && (
                  <div style={{ color: "red" }}>{errorMessage?.rePassword}</div>
                )}

                <div className="loginBtn">
                  <SubmitButton
                    isLoading={forgotPasswordMutation.status === "loading"}
                    click={updatePassword}
                    text={"Update Password"}
                    className={"btn"}
                  />
                </div>
              </form>
            </div>
          ) : (
            <form onSubmit={resetPassword}>
              <Input
                type="text"
                id="fname"
                name="username"
                placeholder="Username"
                value={username}
                onChange={handleForgotUsername}
              />
              {errorMessage?.username && (
                <div style={{ color: "red" }}>{errorMessage?.username}</div>
              )}
              <div className="col-md-12">
                <div className="regsiter_form_box_tandc">
                  {/* <HCaptcha
                    sitekey={SITEKEY}
                    onVerify={setToken}
                    ref={captchaRef}
                  /> */}
                  <ReCAPTCHA
                      sitekey={CAPTCHA_SITEKEY}
                      onChange={setToken}
                    />
                  {errorMessage["token"] && (
                    <span className="error-message-validator">
                      {/* *{t("required")} */}
                      Required
                    </span>
                  )}
                </div>
              </div>
              <div className="loginBtn">
                <SubmitButton
                  isLoading={changePasswordMutation.status === "loading"}
                  click={resetPassword}
                  text={"Change Password"}
                  className={"btn"}
                />
              </div>
            </form>
          )}
        </div>
      </div>
    </>
  );
};

export default LoginForm;
