import React, { createContext, useState, useEffect, useContext, useRef } from 'react';
import { usdkFundTopUpCheck } from '../store/actions/ewalletAction';
import { TOPUP_POLL_INTERVAL, TOPUP_POLL_MAX_TIME } from '../config/config';

const TopupPollingContext = createContext(null);

export const TopupPollingProvider = ({ children }) => {
    const [newDataAvailable, setNewDataAvailable] = useState(false);
    const [isTopupPolling, setIsTopupPolling] = useState(false);
    const TopupPollingIntervalRef = useRef(null); // To store the interval ID
    const TopupPollingTimeoutRef = useRef(null); // To store the timeout ID for stopping polling

    const pollData = async () => {
        try {
            const response = await usdkFundTopUpCheck();

            if (response.data?.data?.importedTransactionsCount > 0) {
                setNewDataAvailable(true); // Mark new data as available
                stopTopupPolling(); // Stop polling if data is found
            }
        } catch (error) {
            console.error('Error polling data:', error);
        }
    };

    // Function to start TopupPolling
    const startTopupPolling = () => {
        if (!isTopupPolling) {
            setIsTopupPolling(true);

            // Set a timeout to stop polling after TOPUP_POLL_MAX_TIME
            TopupPollingTimeoutRef.current = setTimeout(() => {
                stopTopupPolling();
            }, TOPUP_POLL_MAX_TIME * 1000); // Convert seconds to milliseconds
        }
    };

    // Function to stop TopupPolling
    const stopTopupPolling = () => {
        if (isTopupPolling) {
            setIsTopupPolling(false);

            // Clear polling interval
            if (TopupPollingIntervalRef.current) {
                clearInterval(TopupPollingIntervalRef.current);
                TopupPollingIntervalRef.current = null;
            }

            // Clear the timeout if it's still active
            if (TopupPollingTimeoutRef.current) {
                clearTimeout(TopupPollingTimeoutRef.current);
                TopupPollingTimeoutRef.current = null;
            }
        }
    };

    useEffect(() => {
        if (isTopupPolling) {
            // Make an initial poll when polling starts
            pollData();

            // Set up the interval for subsequent polls
            TopupPollingIntervalRef.current = setInterval(pollData, TOPUP_POLL_INTERVAL);
        } else if (TopupPollingIntervalRef.current) {
            clearInterval(TopupPollingIntervalRef.current); // Stop polling
            TopupPollingIntervalRef.current = null;
        }

        return () => {
            if (TopupPollingIntervalRef.current) {
                clearInterval(TopupPollingIntervalRef.current); // Cleanup interval on unmount
            }
            if (TopupPollingTimeoutRef.current) {
                clearTimeout(TopupPollingTimeoutRef.current); // Cleanup timeout on unmount
            }
        };
    }, [isTopupPolling]);

    useEffect(() => {
        // Perform a unique poll when the component mounts
        pollData();
    }, []); // Run only once on mount

    return (
        <TopupPollingContext.Provider
            value={{
                newDataAvailable,
                setNewDataAvailable,
                startTopupPolling,
                stopTopupPolling,
                isTopupPolling,
            }}
        >
            {children}
        </TopupPollingContext.Provider>
    );
};

export const useTopupPolling = () => {
    const context = useContext(TopupPollingContext);
    if (!context) {
        throw new Error('useTopupPolling must be used within a TopupPollingProvider');
    }
    return context;
};
