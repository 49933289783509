import React from 'react';
import { useTranslation } from "react-i18next";

export const TableContentNoDataFound = () => {
    const {t} = useTranslation()    ;

    return (
        <tbody>
        <tr>
            <td colSpan="6">
                <div className="nodata-table-view">
                    <div className="nodata-table-view-box">
                        <div className="nodata-table-view-box-img">
                            <img src="/images/no-data-image1.png" alt=""/>
                        </div>
                        <div className="nodata-table-view-box-txt">
                            {t("sorry_no_data_found")}
                        </div>
                    </div>
                </div>
            </td>
        </tr>
        </tbody>
    )
}
