import OptionsButton from '../Common/buttons/OptionsButton';
import React from 'react';

const OptionButtonsBar = ({buttons}) => {
    return <div className="d-flex justify-content-end align-items-center gap-2">
        { buttons.map(({title, handleOpen, type, link }, index) => <OptionsButton key={`option-button-${index}--${title}`} title={title} link={link} handleOpen={handleOpen} type={type} />)}
    </div>
}

export default OptionButtonsBar;
