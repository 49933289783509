import React, { useState } from "react";
import { NavLink } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";
import { ApiHook } from "../../hooks/apiHook";

const BalancesViewNavbar = ({ menu }) => {
    const { t } = useTranslation();
    const location = useLocation();
    const pathname = location.pathname;
    const menuOptions = [
        { path: 'usx', slug: 'usx' },
        { path: 'usx-w', slug: 'usxw' },
        { path: 'usx-promo', slug: 'usxp' },
        // { path: 'usdkc-wallet', slug: 'usdkc' },
    ]
    const currentSlug = menuOptions.find(item => `/${item.path}` == pathname)?.slug;

    const getClassName = ({ isActive }) => isActive ? "btn_ewallt_page active" : "btn_ewallt_page";

    return (
        <>
            <div className="page_head_top">
                {t(currentSlug)}
            </div>
            <div className={`balances_view_navbar`}>
                    {
                        menuOptions.map((menuOption, index) =>
                            <NavLink key={`balances_view_navbar--${index}--${menuOption.path}`} to={`/${menuOption.path}`}
                                     className={getClassName}>{t(menuOption.slug)}</NavLink>)
                    }
            </div>
        </>
    );
};

export default BalancesViewNavbar;
