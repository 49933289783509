import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { UsdkFundTopUp } from "../../components/Wallet/UsdkFundTopUp"
import BalancesViewNavbar from '../../components/Balances/BalancesViewNavbar';
import OptionButtonsBar from '../../components/Balances/OptionButtonsBar';
import { useTopupPolling } from '../../contexts/TopupPollingContext';
import { usdkTiles } from '../../store/actions/ewalletAction';
import { WalletTransferOffCanvas } from '../../components/Wallet/CommonComponents/WalletTransferOffCanvas';
import { WalletTiles } from '../../components/Wallet/CommonComponents/WalletTiles';
import { WalletTable } from '../../components/Wallet/CommonComponents/WalletTable';

const MainUsdkWallet = () => {
    const [showTransfer, setShowTransfer] = useState(false);
    const [showTopUp, setShowTopUp] = useState(false);
    const [currentPage, setCurrentPage] = useState(1);
    const [swap, setSwap] = useState(false);
    const [ewallet, setEwallet] = useState(false);
    const { newDataAvailable, setNewDataAvailable } = useTopupPolling();

    const fetchTilesData = async () => {
        const theTiles = await usdkTiles()
        setEwallet(theTiles);
    }

    useEffect(() => {
        fetchTilesData();
    }, []);

    useEffect(() => {
        if (newDataAvailable) {
            fetchTilesData();
        }
    }, [newDataAvailable, setNewDataAvailable]);


    const handleSwap = () => {
        setSwap((prevShowTransfer) => !prevShowTransfer);
    };

    const handleEwalletTopUp = () => {
        setShowTopUp((prevShowTopUp) => !prevShowTopUp);
    };

    const handleEwalletTransfer = () => {
        fetchTilesData();
        setShowTransfer((prevShowTransfer) => !prevShowTransfer);
    };

    const userSelectedCurrency = useSelector(
        (state) => state.user?.selectedCurrency
    );
    const conversionFactor = useSelector(
        (state) => state?.user?.conversionFactor
    );

    const optionButtons = [
        // { title: 'usdk_fund_transfer', handleOpen: handleEwalletTransfer, type: 'ewallet' },
        { title: 'usdkFundTopUp', handleOpen: handleEwalletTopUp, type: 'ewallet' }
    ];

    return (
      <>
          <div className="navbar-wrapper mb-2">
              <BalancesViewNavbar/>
              <OptionButtonsBar buttons={optionButtons}/>
          </div>
          <WalletTiles
              wallet={`usx`}
              tiles={ewallet}
              currency={userSelectedCurrency}
              conversionFactor={conversionFactor}
          >
          </WalletTiles>
          <WalletTable
            wallet={`usx`}
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
            currency={userSelectedCurrency}
          >
          </WalletTable>
          <WalletTransferOffCanvas
              balance={ewallet?.balance}
              show={showTransfer}
              handleClose={handleEwalletTransfer}
              fromWallet={`usx`}
          />
          <UsdkFundTopUp
              balance={ewallet?.balance}
              show={showTopUp}
              handleClose={handleEwalletTopUp}
              currency={userSelectedCurrency}
          />
      </>
  );
};

export default MainUsdkWallet;
