import { EwalletService } from "../../services/ewallet/ewallet";

export const Tiles = async () => {
  try {
    const response = await EwalletService.callTiles();
    return response;
  } catch (error) {
    return error.message;
  }
};

export const Statement = async (page, itemsPerPage, sortBy, direction) => {
  try {
    const response = await EwalletService.callStatement(
      page,
      itemsPerPage,
      sortBy,
      direction
    );
    return response;
  } catch (error) {
    return error.message;
  }
};

export const TransferHistory = async (
  page,
  itemsPerPage,
  selectedCategory,
  startDate,
  endDate,
  sortBy,
  direction
) => {
  try {
    const response = await EwalletService.callTransferHistory(
      page,
      itemsPerPage,
      selectedCategory,
      startDate,
      endDate,
      sortBy,
      direction
    );
    return response;
  } catch (error) {
    return error.message;
  }
};

export const PurchaseHistory = async (
  page,
  itemsPerPage,
  sortBy,
  direction
) => {
  try {
    const response = await EwalletService.callPurchaseHistory(
      page,
      itemsPerPage,
      sortBy,
      direction
    );
    return response;
  } catch (error) {
    return error.message;
  }
};

export const MyEarnings = async (
  page,
  itemsPerPage,
  selectedCategory,
  startDate,
  endDate,
  sortBy,
  direction
) => {
  try {
    const response = await EwalletService.callMyEarnings(
      page,
      itemsPerPage,
      selectedCategory,
      startDate,
      endDate,
      sortBy,
      direction
    );
    return response;
  } catch (error) {
    return error.message;
  }
};

export const FundTransfer = async (data) => {
  try {
    const response = await EwalletService.callFundTransfer(data);
    return response;
  } catch (error) {
    return error.message;
  }
};

export const EwalletBalance = async () => {
  try {
    const response = await EwalletService.callEwalletBalance();
    return response;
  } catch (error) {
    console.log(error.message);
  }
};

export const requestWithdrawal = async (data) => {
  try {
    const response = await EwalletService.callRequestWithdrawal(data);
    return response;
  } catch (error) {
    return error.message;
  }
};

//USDKC

export const usdkCTiles = async () => {
  try {
    const response = await EwalletService.callUSDKCTiles();
    return response;
  } catch (error) {
    return error.message;
  }
};

export const usdkCStatement = async (page, itemsPerPage, sortBy, direction) => {
  try {
    const response = await EwalletService.callUsdkCStatement(
      page,
      itemsPerPage,
      sortBy,
      direction
    );
    return response;
  } catch (error) {
    return error.message;
  }
};

export const usdkCTransferHistory = async (
  page,
  itemsPerPage,
  selectedCategory,
  startDate,
  endDate,
  sortBy,
  direction
) => {
  try {
    const response = await EwalletService.callUsdkcTransferHistory(
      page,
      itemsPerPage,
      selectedCategory,
      startDate,
      endDate,
      sortBy,
      direction
    );
    return response;
  } catch (error) {
    return error.message;
  }
};

export const USDKCMyEarnings = async (
  page,
  itemsPerPage,
  selectedCategory,
  startDate,
  endDate,
  sortBy,
  direction
) => {
  try {
    const response = await EwalletService.callUSDKCMyEarnings(
      page,
      itemsPerPage,
      selectedCategory,
      startDate,
      endDate,
      sortBy,
      direction
    );
    return response;
  } catch (error) {
    return error.message;
  }
};

export const usdkCFundTransfer = async (data) => {
  try {
    const response = await EwalletService.callUSDKCFundTransfer(data);
    return response;
  } catch (error) {
    return error.message;
  }
};

export const usdkCSwap = async (data) => {
  try {
    const response = await EwalletService.callUSDKCSwap(data);
    return response;
  } catch (error) {
    return error.message;
  }
};

export const usdkWSwap = async (data) => {
  try {
    const response = await EwalletService.callUSDKWSwap(data);
    return response;
  } catch (error) {
    return error.message;
  }
};

export const sendToHomnifi = async (data) => {
  try {
    const response = await EwalletService.callSendToHomnifi(data);
    return response;
  } catch (error) {
    return error.message;
  }
};

//USDK

export const usdkTiles = async () => {
  try {
    const response = await EwalletService.callUSDKTiles();
    return response;
  } catch (error) {
    return error.message;
  }
};

export const usdkStatement = async (page, itemsPerPage, sortBy, direction) => {
  try {
    const response = await EwalletService.callUsdkStatement(
      page,
      itemsPerPage,
      sortBy,
      direction
    );
    return response;
  } catch (error) {
    return error.message;
  }
};

export const usdkTransferHistory = async (
  page,
  itemsPerPage,
  selectedCategory,
  startDate,
  endDate,
  sortBy,
  direction
) => {
  try {
    const response = await EwalletService.callUsdkTransferHistory(
      page,
      itemsPerPage,
      selectedCategory,
      startDate,
      endDate,
      sortBy,
      direction
    );
    return response;
  } catch (error) {
    return error.message;
  }
};

export const USDKMyEarnings = async (
  page,
  itemsPerPage,
  selectedCategory,
  startDate,
  endDate,
  sortBy,
  direction
) => {
  try {
    const response = await EwalletService.callUSDKMyEarnings(
      page,
      itemsPerPage,
      selectedCategory,
      startDate,
      endDate,
      sortBy,
      direction
    );
    return response;
  } catch (error) {
    return error.message;
  }
};

export const usdkFundTransfer = async (data) => {
  try {
    const response = await EwalletService.callUSDKFundTransfer(data);
    return response;
  } catch (error) {
    return error.message;
  }
};

export const usdkFundTopUp = async (data) => {
  try {
    const response = await EwalletService.callUSDKFundTopUp(data);
    return response;
  } catch (error) {
    return error.message;
  }
};

export const usdkFundTopUpCheck = async (data) => {
  try {
    const response = await EwalletService.callUSDKFundTopUpCheck(data);
    return response;
  } catch (error) {
    return error.message;
  }
};

export const usdkSwap = async (data) => {
  try {
    const response = await EwalletService.callUSDKSwap(data);
    return response;
  } catch (error) {
    return error.message;
  }
};
//USDK Promo
export const usdkPromoTiles = async () => {
  try {
    const response = await EwalletService.callUSDKPromoTiles();
    return response;
  } catch (error) {
    return error.message;
  }
};

export const usdkPromoStatement = async (page, itemsPerPage, sortBy, direction) => {
  try {
    const response = await EwalletService.callUsdkPromoStatement(
      page,
      itemsPerPage,
      sortBy,
      direction
    );
    return response;
  } catch (error) {
    return error.message;
  }
};

export const usdkPromoTransferHistory = async (
  page,
  itemsPerPage,
  selectedCategory,
  startDate,
  endDate,
  sortBy,
  direction
) => {
  try {
    const response = await EwalletService.callUsdkPromoTransferHistory(
      page,
      itemsPerPage,
      selectedCategory,
      startDate,
      endDate,
      sortBy,
      direction
    );
    return response;
  } catch (error) {
    return error.message;
  }
};

export const usdkPromoFundTransfer = async (data) => {
  try {
    const response = await EwalletService.callUSDKPromoFundTransfer(data);
    return response;
  } catch (error) {
    return error.message;
  }
};

export const UsdkBalance = async () => {
  try {
    console.log('UsdkBalance callignEwalletService.callUSDKBalance');
    const response = await EwalletService.callUsdkBalance();
    return response;
  } catch (error) {
    console.log(error.message);
  }
};
