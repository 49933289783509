import React, { useState } from "react";
import BalancesViewNavbar from '../../components/Balances/BalancesViewNavbar';
import { ApiHook } from "../../hooks/apiHook";
import { useSelector } from "react-redux";
import OptionButtonsBar from '../../components/Balances/OptionButtonsBar';
import TransferToHomnifi from "../../components/Wallet/TransferToHomnifi";
import { WalletSwapOffCanvas } from '../../components/Wallet/CommonComponents/WalletSwapOffCanvas';
import { WalletTransferOffCanvas } from '../../components/Wallet/CommonComponents/WalletTransferOffCanvas';
import { WalletTiles } from '../../components/Wallet/CommonComponents/WalletTiles';
import { WalletTable } from '../../components/Wallet/CommonComponents/WalletTable';

const EwalletLayout = () => {
    const [showTransfer, setShowTransfer] = useState(false);
    const [swap, setSwap] = useState(false);
    const [currentPage, setCurrentPage] = useState(1);
    const [showSendToHomnifi, setShowSendToHomnifi] = useState(false);

    const handleEwalletTransfer = () => {
        setShowTransfer((prevShowTransfer) => !prevShowTransfer);
    };

    const handleTransferToHomnifi = () => {
        setShowSendToHomnifi((prevShowTransfer) => !prevShowTransfer)
    };

    const handleSwap = () => {
        setSwap((prevShowTransfer) => !prevShowTransfer);
    };

    const ewallet = ApiHook.CallEwalletTiles();

    const userSelectedCurrency = useSelector(
        (state) => state.user?.selectedCurrency
    );
    const conversionFactor = useSelector(
        (state) => state?.user?.conversionFactor
    );

    const optionButtons = [
        { title: 'ewallet_request_withdrawal', link: '/payout', type: 'ewallet'},
        // { title: 'ewallet_fund_transfer', handleOpen: handleEwalletTransfer, type: 'ewallet'},
        // { title: 'swap', handleOpen: handleSwap, type: 'ewallet' },
        // { title: 'usdkc_fund_transfer_homnifi', handleOpen: handleTransferToHomnifi, type: 'ewallet'}
    ];

    return (
        <>
            <div className="navbar-wrapper mb-2">
                <BalancesViewNavbar/>
                <OptionButtonsBar buttons={optionButtons}/>
            </div>
            <WalletTiles
                wallet={`usxw`}
                tiles={ewallet?.data}
                currency={userSelectedCurrency}
                conversionFactor={conversionFactor}
            >
            </WalletTiles>
            <WalletTable
                wallet={`usxw`}
                currentPage={currentPage}
                setCurrentPage={setCurrentPage}
                currency={userSelectedCurrency}
            >
            </WalletTable>
            <WalletTransferOffCanvas
                balance={ewallet?.data?.balance}
                show={showTransfer}
                handleClose={handleEwalletTransfer}
                fromWallet={`usxw`}
            />
            <TransferToHomnifi
                walletType={'usdkw'}
                balance={ewallet?.data?.balance}
                show={showSendToHomnifi}
                handleClose={handleTransferToHomnifi}
                currency={userSelectedCurrency}
            />
            <WalletSwapOffCanvas
                fromWallet={`usxw`}
                toWallet={`usx`}
                balance={ewallet?.data?.balance}
                show={swap}
                handleClose={handleSwap}
            >
            </WalletSwapOffCanvas>
        </>
    );
};

export default EwalletLayout;
