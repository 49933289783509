import React, { useState, useEffect } from "react";
import CurrencyConverter from "../../Currency/CurrencyConverter";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import { ApiHook } from "../../hooks/apiHook";
import { useNavigate } from "react-router";
import { EwalletService } from "../../services/ewallet/ewallet";
import { Col, Container, Form, Row } from "react-bootstrap";
import { MonetizationOn, Payments, Wallet } from "@mui/icons-material";
import Slider from "@mui/material/Slider";
import { useRef } from "react";


const CheckoutPayment = ({
  register,
  formValues,
  setValue,
  errors,
  totalAmount,
  payments,
  haveSponsor,
}) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const [paymentMethods, setPaymentMethods] = useState({
    usdk: {
      enabled: false,
      amount: totalAmount,
      balance: 0,
    },
    "usdk-promo": {
      enabled: false,
      amount: 0,
      balance: 0,
    },
  });

  const [promoAmount, setPromoAmount] = useState(0);
  const [usdkInputValue, setUsdkInputValue] = useState(String(totalAmount));
  const typingTimeoutRef = useRef(null);



  useEffect(() => {
    setValue("paymentTypes", { usdk: totalAmount, "usdk-promo": 0 });
    setValue("totalAmount", totalAmount);
  }, [totalAmount, setValue]);

  useEffect(() => {
    const fetchBalance = async () => {
      try {
        const balance = await EwalletService.callUsdkBalance();
        setPaymentMethods((prev) => ({
          ...prev,
          usdk: {
            ...prev.usdk,
            balance: balance.usdkWallet,
          },
          "usdk-promo": {
            ...prev["usdk-promo"],
            balance: balance.usdkPromoWallet,
          },
        }));
      } catch (error) {
        console.error("Error fetching USDX balance:", error);
      }
    };

    fetchBalance();
  }, []);

  const conversionFactor = useSelector(
    (state) => state?.user?.conversionFactor
  );

  const repurchaseMutation = ApiHook.CallPlaceSplitRepurchaseOrder();

  const handleSliderChange = (event, newValue) => {
    let maxPromoAllowed = totalAmount * 0.5;

    maxPromoAllowed = Math.min(
      maxPromoAllowed,
      paymentMethods["usdk-promo"].balance
    );

    let adjustedPromoValue = Math.min(newValue, maxPromoAllowed);

    let usdkAmount = totalAmount - adjustedPromoValue;

    if (usdkAmount > paymentMethods.usdk.balance) {
      const exceso = usdkAmount - paymentMethods.usdk.balance;
      adjustedPromoValue += exceso;

      adjustedPromoValue = Math.min(adjustedPromoValue, maxPromoAllowed);
      usdkAmount = totalAmount - adjustedPromoValue;
    }

    setValue("paymentTypes", {
      usdk: usdkAmount,
      "usdk-promo": adjustedPromoValue,
    });

    setPromoAmount(adjustedPromoValue);

    setPaymentMethods((prev) => ({
      ...prev,
      usdk: {
        ...prev.usdk,
        amount: usdkAmount,
      },
      "usdk-promo": {
        ...prev["usdk-promo"],
        amount: adjustedPromoValue,
      },
    }));
  };

  const handleSubmit = () => {
    // if (!formValues?.sponsorEmail) {
    //   toast.error(t("add_sponsor_email"));
    //   return;
    // }

    setValue("paymentTypes", {
      usdk: paymentMethods.usdk.amount || 0,
      "usdk-promo": paymentMethods["usdk-promo"].amount || 0,
    });

    // TODO: This thing is not working
    setValue("distributorId", localStorage.getItem("distributorId"));
    const distributorId = localStorage.getItem("distributorId");

    repurchaseMutation.mutate({...formValues, distributorId}, {
      onSuccess: (res) => {
        if (res?.status) {
          toast.success(t(res?.data?.message));
          navigate("/shopping");
        } else {
          toast.error(res?.data?.description);
        }
      },
    });
  };


  const handleUsdkChange = (e) => {
    // Simplemente guardamos lo que se está tecleando
    setUsdkInputValue(e.target.value);
    if (typingTimeoutRef.current) {
        clearTimeout(typingTimeoutRef.current);
      }
      
      typingTimeoutRef.current = setTimeout(() => {
        applyUsdkValidations(e.target.value);
      }, 500);
  };

  const applyUsdkValidations = (value) => {
    // let typedAmount = parseFloat(usdkInputValue);
    let typedAmount = parseFloat(value || usdkInputValue);
    // Si es NaN (input vacío o caracter no numérico), forzamos a 0
    if (isNaN(typedAmount)) {
      typedAmount = 0;
    }
    // No menor que 0
    if (typedAmount < 0) {
      typedAmount = 0;
    }
    // No mayor que total de la compra
    if (typedAmount > totalAmount) {
      typedAmount = totalAmount;
    }
    // No exceder balance en USDK
    if (typedAmount > paymentMethods.usdk.balance) {
      typedAmount = paymentMethods.usdk.balance;
    }

    // Cálculo de promo
    let newPromo = totalAmount - typedAmount;
    // Máx 50% del total
    const maxPromoAllowed = totalAmount * 0.5;
    if (newPromo > maxPromoAllowed) {
      newPromo = maxPromoAllowed;
      typedAmount = totalAmount - newPromo;
    }
    // No exceder balance promo
    if (newPromo > paymentMethods["usdk-promo"].balance) {
      newPromo = paymentMethods["usdk-promo"].balance;
      typedAmount = totalAmount - newPromo;
    }

    // Actualizamos el State central
    setPaymentMethods((prev) => ({
      ...prev,
      usdk: { ...prev.usdk, amount: typedAmount },
      "usdk-promo": { ...prev["usdk-promo"], amount: newPromo },
    }));

    // Reseteamos el input con el valor final ajustado
    setUsdkInputValue(String(typedAmount));
    setPromoAmount(newPromo);

  };


  
  return (
    <Container>
      <div>
        <div className="flex gap-2">
          <Payments /> <span>{t("payment_status")}</span>
        </div>

        <div className="flex gap-2 mt-2">
          <div className="flex flex-col border border-gray-300 pt-2 px-3 pb-20 rounded-md max-w-sm w-full ">
            <span className="text-xs text-gray-500">
              {t("total_amount_to_pay")}
            </span>
            <span className="text-4xl font-extrabold mt-2">
              ${CurrencyConverter(totalAmount, conversionFactor)}
            </span>
          </div>

          {/* <div className="flex flex-col border border-gray-300 pt-2 px-3 pb-20 rounded-md max-w-sm w-full ">
            <span className="text-xs text-gray-500">
              {t("total_amount_from_usx")}
            </span>
            <span className="text-4xl font-extrabold mt-2">
              ${CurrencyConverter(paymentMethods.usdk.amount, conversionFactor)}
            </span>
          </div> */}
            <div className="flex flex-col border border-gray-300 pt-2 px-3 pb-20 rounded-md max-w-sm w-full">
            <span className="text-xs text-gray-500">
              {t("total_amount_from_usx")}
            </span>
            {/* Input de USDK */}
            <input
              type="number"
              className="text-4xl font-extrabold mt-2 bg-transparent outline-none"
              value={usdkInputValue}
              onChange={handleUsdkChange}
              // onBlur={applyUsdkValidations}
              // Step y límites suaves en el input (opcional)
              min={0}
              step="0.01"
            />
          </div>
        
          <div className="flex flex-col border border-gray-300 pt-2 px-3 pb-20 rounded-md max-w-sm w-full ">
            <span className="text-xs text-gray-500">
              {t("total_amount_from_usx_promo")}
            </span>
            <span className="text-4xl font-extrabold mt-2">
              $
              {CurrencyConverter(
                paymentMethods["usdk-promo"].amount,
                conversionFactor
              )}
            </span>
          </div>
        </div>

        <div className="mt-4">
          <div className="flex gap-2">
            <Wallet /> <span>{t("payment_method")}</span>
          </div>
          <span className="text-xs text-gray-500">
            {t("select_your_payment_method")}
          </span>
        </div>
        <div className="mt-2" />

        <div className="flex flex-row border-blue-500 bg-blue-50 border-1 p-2 rounded-md w-full justify-between items-center">
          <div className="flex flex-row gap-2 items-center">
            <MonetizationOn className="text-blue-500" />
            <span>USX Balance: {paymentMethods["usdk"].balance}</span>
            <span>
              {" "}
              | Promo Balance: {paymentMethods["usdk-promo"].balance}
            </span>
          </div>
        </div>

        {/* Slider para ajustar USDK-Promo */}
        <div className="mt-4">
          <h6>
            {t("use_promo")} ({t("max")} 50%):
            <strong> {paymentMethods["usdk-promo"].amount} USX</strong>
          </h6>
          <Slider
            value={promoAmount}
            onChange={handleSliderChange}
            step={0.1}
            min={0}
            max={Math.min(
              totalAmount * 0.5,
              paymentMethods["usdk-promo"].balance
            )}
            valueLabelDisplay="auto"
          />
          <div>
            <p>
              <strong>USDX-Promo: </strong>
              {paymentMethods["usdk-promo"].amount} USX
            </p>
            <p>
              <strong>USDX: </strong>
              {paymentMethods.usdk.amount} USX
            </p>
          </div>
        </div>

        {/* {!haveSponsor && (
          <Row className="mt-4 mb-4">
            <Col md={3}>
              <Form.Label>Sponsor</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter sponsor email"
                {...register("sponsorEmail")}
                isInvalid={!!errors.sponsorEmail}
              />
            </Col>
          </Row>
        )} */}

        <div className="flex flex-row gap-2 mt-2">
          <button
            className={
              Number(paymentMethods.usdk.balance) === 0
                ? "btn p-2 text-white btn-primary w-full disabled "
                : "btn p-2 text-white btn-primary w-full"
            }
            onClick={handleSubmit}
          >
            {t("buy_now")}
          </button>

          <button
            className="btn !text-gray-400 !bg-gray-200 max-w-[150px] w-full"
            onClick={() => navigate("/shopping")}
          >
            {t("cancel")}
          </button>
        </div>
      </div>
    </Container>
  );
};

export default CheckoutPayment;
