const today = new Date();
const currentDay = today.getDay(); // 0 = Sunday, 1 = Monday, ..., 6 = Saturday

const thisMonday = new Date(today);
thisMonday.setDate(today.getDate() - currentDay + (currentDay === 0 ? -6 : 1));

const thisSunday = new Date(thisMonday);
thisSunday.setDate(thisMonday.getDate() + 6);

const lastMonday = new Date(today);
lastMonday.setDate(today.getDate() - currentDay - 6);

const lastSunday = new Date(today);
lastSunday.setDate(today.getDate() - currentDay);

export const filtersWithDates = [
    {
        name: "today",
        startDate: today.toISOString().split('T')[0],
        endDate: today.toISOString().split('T')[0]
    },
    {
        name: "this-week",
        startDate: thisMonday.toISOString().split('T')[0],
        endDate: thisSunday.toISOString().split('T')[0]
    },
    {
        name: "last-week",
        startDate: lastMonday.toISOString().split('T')[0],
        endDate: lastSunday.toISOString().split('T')[0]
    },
    {
        name: "this-month",
        startDate: new Date(today.getFullYear(), today.getMonth(), 1).toISOString().split('T')[0],
        endDate: new Date(today.getFullYear(), today.getMonth() + 1, 0).toISOString().split('T')[0]
    },
    {
        name: "last-month",
        startDate: new Date(today.getFullYear(), today.getMonth() - 1, 1).toISOString().split('T')[0],
        endDate: new Date(today.getFullYear(), today.getMonth(), 0).toISOString().split('T')[0]
    },
    {
        name: "this-year",
        startDate: new Date(today.getFullYear(), 0, 1).toISOString().split('T')[0],
        endDate: new Date(today.getFullYear(), 11, 31).toISOString().split('T')[0]
    },
    {
        name: "last-year",
        startDate: new Date(today.getFullYear() - 1, 0, 1).toISOString().split('T')[0],
        endDate: new Date(today.getFullYear() - 1, 11, 31).toISOString().split('T')[0]
    },
    {
        name: "historical",
        isDefault: true,
        startDate: null,
        endDate: null
    },
    {
        name: "custom",
        startDate: null,
        endDate: null
    }
];

export const getAvailableFilters = (setStartDate, setEndDate) => {
    return filtersWithDates.map((filter) => {
        return ({
            ...filter,
            onChange: () => {
                setStartDate(filter.startDate);
                setEndDate(filter.endDate);
            }
        });
    });
};
