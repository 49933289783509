import React, { useEffect, useState } from "react";
import anime from "animejs/lib/anime.es.js";
import RegisterModal from "../../components/Common/modals/RegisterModal";
import ShopRegisterForm from "../../components/Register/ShopRegisterForm";
import { ApiHook } from "../../hooks/apiHook";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";

const ShopRegisterLayout = () => {
    const location = useLocation();
    let placement = location?.state?.parent;
    let position = location?.state?.position;

    const [showRegisterModal, setShowRegisterModal] = useState(false);
    const [activeStep, setActiveStep] = useState(2);
    const { t } = useTranslation();

    const registerFields = ApiHook.CallShopRegisterFields();

    const handleToggleRegisterModal = () => {
        setShowRegisterModal(!showRegisterModal);
    };

    // animation for the register
    const animateStepTransition = (nextStep) => {
        const containerElement = document.querySelector("#animation");
        const nextStepElement = document.querySelector(
            `.register-left-cnt-row:nth-child(${nextStep + 1})`
        );

        // Set initial position of the next step
        anime.set(containerElement, { opacity: 0 });
        anime.set(nextStepElement, { translateY: "100%" });

        // Animate the next step in
        anime({
            targets: containerElement,
            opacity: [0, 1],
            duration: 1000,
            easing: "easeInOutQuad",
        });
        anime({
            targets: nextStepElement,
            translateY: "0%",
            duration: 500,
            easing: "easeInOutQuad",
        });
    };

    return (
        <>
            {/* <div className="container"> */}
            <div className="flex flex-row justify-content-center items-center h-[100vh]">
                <div className="register_row align-items-center justify-content-center">
                    <div className="main-regsiter-left-section">
                        <div className="register-left-cnt-row opacity-1">
                            <h2>{t("registerNow")}</h2>
                        </div>
                        {registerFields?.data?.registrationSteps?.map((step, index) => (
                            <div
                                key={index}
                                className={`register-left-cnt-row ${((registerFields?.data?.regData === 0 || registerFields?.data?.regData === null) && (registerFields?.data?.regPack === null || registerFields?.data?.regPack.length === 0)) ? (step.step === activeStep - 1 ? "active" : ""): (step.step === activeStep ? "active" : "")}`}
                            >
                                <span>{step.step}</span> {t(`${step.label}`)}
                            </div>
                        ))}
                    </div>
                    <ShopRegisterForm
                        activeStep={activeStep}
                        setActiveStep={setActiveStep}
                        handleToggleRegisterModal={handleToggleRegisterModal}
                        animateStepTransition={animateStepTransition}
                        data={registerFields?.data}
                        placement={placement}
                        position={position}
                    />
                </div>
            </div>
            <RegisterModal
                show={showRegisterModal}
                handleClose={handleToggleRegisterModal}
                data={registerFields?.data?.termsAndCondition?.termsAndConditions}
            />
        </>
    );
};

export default ShopRegisterLayout;
