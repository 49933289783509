import { JSEncrypt } from "jsencrypt";

export function cutText(text: string, maxLength: number) {
  if (!text?.length) return "";
  return text.length > maxLength ? `${text.slice(0, maxLength)}...` : text;
}

export function toCapitalCase(text: string) {
  if (!text?.length) return "";
  return text.charAt(0).toUpperCase() + text.slice(1);
}

export function formatMoney(amount: number) {
  return Number(amount).toLocaleString("en-US", {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  });
}

export function RSAencrypt(val: string): string | false {
  if (!val) return false;
  const publicKey = process.env.REACT_APP_RSA_PUBLIC_KEY as string;
  const crypt = new JSEncrypt();
  crypt.setKey(publicKey);
  const encryptedNumber = crypt.encrypt(val);
  return encryptedNumber || false;
}
