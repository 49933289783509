import React, { useEffect, useState } from "react";
import TableMain from "../../Common/table/TableMain";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { getWalletConfig } from '../walletConfigs';
import UsdkTableFilter from "../../Common/table/UsdkTableFilter";

export const WalletTable = ({wallet, currentPage, setCurrentPage}) => {
    const { t } = useTranslation();
    const [activeTab, setActiveTab] = useState("statement");
    const [selectStatement, setSelectedStatement] = useState(false);
    const [selectedTransfer, setSelectedTransfer] = useState(false);
    const [selectedEarnings, setSelectedEarnings] = useState(false);
    const [itemsPerPage, setItemsPerPage] = useState(10);
    const [selectedCategory, setSelectedCategory] = useState([]);
    const [dateRange, setDateRange] = useState({ startDate: "", endDate: "" });
    const [sortOptions, setSortOptions] = useState({ sortBy: "", direction: "" });

    const walletConfig = getWalletConfig(wallet);

    //------------------------------------------- API -------------------------------------------
    const statement = walletConfig?.tableHooks?.statement
        ? walletConfig.tableHooks.statement(
            currentPage,
            itemsPerPage,
            selectStatement,
            sortOptions.sortBy,
            sortOptions.direction,
            activeTab
        )
        : null;

    const transferHistory = walletConfig?.tableHooks?.transferHistory ?
        walletConfig?.tableHooks?.transferHistory(
            currentPage,
            itemsPerPage,
            selectedTransfer,
            setSelectedTransfer,
            selectedCategory,
            dateRange?.startDate,
            dateRange?.endDate,
            sortOptions.sortBy,
            sortOptions.direction,
            activeTab
        ) : null;

    const myEarnings = walletConfig?.tableHooks?.earnings ?
        walletConfig?.tableHooks?.earnings(
            currentPage,
            itemsPerPage,
            selectedEarnings,
            setSelectedEarnings,
            selectedCategory,
            dateRange?.startDate,
            dateRange?.endDate,
            sortOptions.sortBy,
            sortOptions.direction,
            activeTab
        ) : null;

    const handleTabChange = (tab) => {
        setSortOptions({
            sortBy: "",
            direction: "",
        });
        if (tab === "transfer_history" || tab === "my_earnings") {
            setSelectedCategory("");
            setDateRange({ startDate: "", endDate: "" });
        }
        setActiveTab(tab);
        setCurrentPage(1);
        setApiTab(tab);
    };
    const setApiTab = (tab) => {
        if (tab === "transfer_history") {
            setSelectedTransfer(true);
        } else if (tab === "my_earnings") {
            setSelectedEarnings(true);
        } else {
            setSelectedStatement(true);
        }
    };
    const headers =
        activeTab !== "my_earnings"
            // ? ["description", "amount", "transactionDate", "balance"]
            ? ["description", "amount", "transactionDate"]
            : [
                "description",
                "totalAmount",
                "TDS",
                "service_charge",
                "amountPayable",
                "transactionDate",
            ];

    const tabs = [
        statement && { key: "statement", label: t("statement") },
        transferHistory && { key: "transfer_history", label: t("transfer_history") },
        myEarnings && { key: "my_earnings", label: t("my_earnings") },
    ].filter(Boolean);

    return (
        <div className="ewallet_table_section">
            <div className="ewallet_table_section_cnt">
                <div className="ewallet_table_section_cnt_tab_head">
                    {tabs.map((tab) => (
                        <Link
                            key={tab.key}
                            className={`ewallet_tab_btn ${activeTab === tab.key ? "active" : ""}`}
                            onClick={() => handleTabChange(tab.key)}
                        >
                            {tab.label}
                        </Link>
                    ))}
                </div>
            </div>
            <div className="table-responsive min-hieght-table">
                {activeTab === "statement" && (
                    <TableMain
                        headers={headers}
                        data={statement?.data?.data}
                        startPage={1}
                        currentPage={statement?.data?.currentPage}
                        totalPages={statement?.data?.totalPages}
                        setCurrentPage={setCurrentPage}
                        type={wallet}
                        itemsPerPage={itemsPerPage}
                        setItemsPerPage={setItemsPerPage}
                        activeTab={activeTab}
                        setApiTab={setApiTab}
                        setEpinSortOptions={setSortOptions}
                    />
                )}
                {activeTab === "transfer_history" && (
                    <>
                        <UsdkTableFilter
                            type={activeTab}
                            setApiTab={setApiTab}
                            selectedCategory={selectedCategory}
                            setSelectedCategory={setSelectedCategory}
                            setDateRange={setDateRange}
                            setCurrentPage={setCurrentPage}
                        />
                        <TableMain
                            headers={headers}
                            data={transferHistory?.data?.data}
                            startPage={1}
                            currentPage={transferHistory?.data?.currentPage}
                            totalPages={transferHistory?.data?.totalPages}
                            setCurrentPage={setCurrentPage}
                            type={"ewallet"}
                            itemsPerPage={itemsPerPage}
                            setItemsPerPage={setItemsPerPage}
                            activeTab={activeTab}
                            setApiTab={setApiTab}
                            setEpinSortOptions={setSortOptions}
                        />
                    </>
                )}

                {activeTab === "my_earnings" && (
                    <>
                        <UsdkTableFilter
                            type={activeTab}
                            setApiTab={setApiTab}
                            selectedCategory={selectedCategory}
                            setSelectedCategory={setSelectedCategory}
                            setDateRange={setDateRange}
                            category={myEarnings.data?.dropdown}
                            setCurrentPage={setCurrentPage}
                        />
                        <TableMain
                            headers={headers}
                            data={myEarnings?.data?.data}
                            startPage={1}
                            currentPage={currentPage}
                            totalPages={myEarnings?.data?.totalPages}
                            setCurrentPage={setCurrentPage}
                            type={"ewallet"}
                            itemsPerPage={itemsPerPage}
                            setItemsPerPage={setItemsPerPage}
                            activeTab={activeTab}
                            setApiTab={setApiTab}
                            setEpinSortOptions={setSortOptions}
                        />
                    </>
                )}
            </div>
        </div>
    );
};
