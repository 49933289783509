import { ApiHook } from '../../hooks/apiHook';

export const walletConfigs = {
    usx: {
        swapToUSXHook: {},
        transferHook: ApiHook.CallUsdkFundTransfer,
        queries: ["usdk-statement", "usdkwallet-tiles", "usdk-transfer-history"],
        tableHooks: {
            statement: ApiHook.CallUsdkwalletStatement,
            transferHistory: ApiHook.CallUSDKTransferHistory
        }
    },
    usxc: {
        swapToUSXHook: ApiHook.CallUsdkCSwap,
        transferHook: ApiHook.CallUsdkCFundTransfer,
        queries: ["usdkc-statement", "usdkcwallet-tiles", "usdkc-transfer-history"],
        tableHooks: {
            statement: ApiHook.CallUsdkcwalletStatement,
            transferHistory: ApiHook.CallUSDKCTransferHistory,
            earnings: ApiHook.CallUSDKCMyEarnings
        }
    },
    usxw: {
        swapToUSXHook: ApiHook.CallUsdkWSwap,
        transferHook: ApiHook.CallFundTransfer,
        queries: ["statement", "ewallet-tiles", "transfer-history"],
        tableHooks: {
            statement: ApiHook.CallEwalletStatement,
            transferHistory: ApiHook.CallTransferHistory,
            // earnings: ApiHook.CallMyEarnings
        }
    },
    usxp: {
        swapToUSXHook: {},
        transferHook: ApiHook.CallUsdkPromoFundTransfer,
        queries: ["usdk-promo-statement", "usdk-promo-wallet-tiles", "usdk-promo-transfer-history"],
        tableHooks: {
            statement: ApiHook.CallUsdkPromowalletStatement,
            transferHistory: ApiHook.CallUSDKPromoTransferHistory
        }
    }
}

// One day we'll delete this:
walletConfigs.usdk = walletConfigs.usx;
walletConfigs.usdkc = walletConfigs.usxc;
walletConfigs.usdkw = walletConfigs.usxw;
walletConfigs.usdkp = walletConfigs.usxp;
walletConfigs.usdkPromo = walletConfigs.usxp;

export const getWalletConfig = (wallet) => walletConfigs[wallet] || {};
