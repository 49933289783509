import API from "../../api/api";

const callApi = async (endpoint) => {
  try {
    const response = await API.get(endpoint);
    if (response.status === 200) {
      return response?.data?.data;
    } else {
      return response?.data?.data;
    }
  } catch (error) {
    console.log(error);
    throw error;
  }
};

export const EwalletService = {
  callTiles: async () => {
    return callApi("ewallet-tiles");
  },
  callStatement: async (page, itemsPerPage, sortBy, direction) => {
    return callApi(
      `ewallet-statement?page=${page}&perPage=${itemsPerPage}&sortBy=${sortBy}&direction=${direction}`
    );
  },
  callTransferHistory: async (
    page,
    itemsPerPage,
    selectedCategory,
    startDate,
    endDate,
    sortBy,
    direction
  ) => {
    return callApi(
      `/ewallet-transfer-history?type=${selectedCategory}&startDate=${startDate}&endDate=${endDate}&page=${page}&perPage=${itemsPerPage}&sortBy=${sortBy}&direction=${direction}`
    );
  },
  callPurchaseHistory: async (page, itemsPerPage, sortBy, direction) => {
    return callApi(
      `purchase-wallet?page=${page}&perPage=${itemsPerPage}&sortBy=${sortBy}&direction=${direction}`
    );
  },
  callMyEarnings: async (
    page,
    itemsPerPage,
    selectedCategory,
    startDate,
    endDate,
    sortBy,
    direction
  ) => {
    return callApi(
      `my-earnings?page=${page}&perPage=${itemsPerPage}&startDate=${startDate}&endDate=${endDate}&type=${selectedCategory}&sortBy=${sortBy}&direction=${direction}`
    );
  },
  callEwalletBalance: async () => {
    return callApi("get-ewallet-balance");
  },
  callUsdkBalance: async () => {
    return callApi("get-usdk-balance");
  },
  callFundTransfer: async (data) => {
    return API.post("fund-transfer", JSON.stringify(data))
      .then((response) => Promise.resolve(response))
      .catch((error) => Promise.reject(error));
  },
  callUSDKCTiles: async () => {
    return callApi("usdkc-tiles");
  },
  callUsdkCStatement: async (page, itemsPerPage, sortBy, direction) => {
    return callApi(
      `usdkc-statement?page=${page}&perPage=${itemsPerPage}&sortBy=${sortBy}&direction=${direction}`
    );
  },
  callUsdkcTransferHistory: async (
    page,
    itemsPerPage,
    selectedCategory,
    startDate,
    endDate,
    sortBy,
    direction
  ) => {
    return callApi(
      `/usdkc-transfer-history?type=${selectedCategory}&startDate=${startDate}&endDate=${endDate}&page=${page}&perPage=${itemsPerPage}&sortBy=${sortBy}&direction=${direction}`
    );
  },
  callUSDKCMyEarnings: async (
    page,
    itemsPerPage,
    selectedCategory,
    startDate,
    endDate,
    sortBy,
    direction
  ) => {
    return callApi(
      `usdkc-earnings?page=${page}&perPage=${itemsPerPage}&startDate=${startDate}&endDate=${endDate}&type=${selectedCategory}&sortBy=${sortBy}&direction=${direction}`
    );
  },
  callUSDKCFundTransfer: async (data) => {
    return API.post("usdkc-fund-transfer", JSON.stringify(data))
      .then((response) => Promise.resolve(response))
      .catch((error) => Promise.reject(error));
  },
  callUSDKCSwap: async (data) => {
    return API.post("usdkc-swap", JSON.stringify(data))
        .then((response) => Promise.resolve(response))
        .catch((error) => Promise.reject(error));
  },
  callUSDKWSwap: async (data) => {
    return API.post("usdkw-swap", JSON.stringify(data))
        .then((response) => Promise.resolve(response))
        .catch((error) => Promise.reject(error));
  },
  callSendToHomnifi: async (data) => {
    return API.post("send-to-homnifi", JSON.stringify(data))
        .then((response) => Promise.resolve(response))
        .catch((error) => Promise.reject(error));
  },
  callRequestWithdrawal: async (data) => {
    return API.post("request-withdrawal", JSON.stringify(data))
        .then((response) => Promise.resolve(response))
        .catch((error) => Promise.reject(error));
  },
  callUSDKTiles: async () => {
    return callApi("usdk-tiles");
  },
  callUsdkStatement: async (page, itemsPerPage, sortBy, direction) => {
    return callApi(
      `usdk-statement?page=${page}&perPage=${itemsPerPage}&sortBy=${sortBy}&direction=${direction}`
    );
  },
  callUsdkTransferHistory: async (
    page,
    itemsPerPage,
    selectedCategory,
    startDate,
    endDate,
    sortBy,
    direction
  ) => {
    return callApi(
      `/usdk-transfer-history?type=${selectedCategory}&startDate=${startDate}&endDate=${endDate}&page=${page}&perPage=${itemsPerPage}&sortBy=${sortBy}&direction=${direction}`
    );
  },
  callUSDKMyEarnings: async (
    page,
    itemsPerPage,
    selectedCategory,
    startDate,
    endDate,
    sortBy,
    direction
  ) => {
    return callApi(
      `usdk-earnings?page=${page}&perPage=${itemsPerPage}&startDate=${startDate}&endDate=${endDate}&type=${selectedCategory}&sortBy=${sortBy}&direction=${direction}`
    );
  },
  callUSDKFundTransfer: async (data) => {
    return API.post("usdk-fund-transfer", JSON.stringify(data))
      .then((response) => Promise.resolve(response))
      .catch((error) => Promise.reject(error));
  },
  callUSDKFundTopUp: async (data) => {
    return API.post("usdk-fund-topup", JSON.stringify(data))
        .then((response) => Promise.resolve(response))
        .catch((error) => Promise.reject(error));
  },
  callUSDKFundTopUpCheck: async () => {
    return API.get("update-top-ups")
        .then((response) => Promise.resolve(response))
        .catch((error) => Promise.reject(error));
  },
  callUSDKSwap: async (data) => {
    return API.post("usdk-to-usdk-promo-swap", JSON.stringify(data))
      .then((response) => Promise.resolve(response))
      .catch((error) => Promise.reject(error));
  },
  //promo
  callUSDKPromoTiles: async () => {
    return callApi("usdk-promo-tiles");
  },
  callUsdkPromoStatement: async (page, itemsPerPage, sortBy, direction) => {
    return callApi(
      `usdk-promo-statement?page=${page}&perPage=${itemsPerPage}&sortBy=${sortBy}&direction=${direction}`
    );
  },
  callUsdkPromoTransferHistory: async (
    page,
    itemsPerPage,
    selectedCategory,
    startDate,
    endDate,
    sortBy,
    direction
  ) => {
    return callApi(
      `/usdk-promo-transfer-history?type=${selectedCategory}&startDate=${startDate}&endDate=${endDate}&page=${page}&perPage=${itemsPerPage}&sortBy=${sortBy}&direction=${direction}`
    );
  },
  callUSDKPromoFundTransfer: async (data) => {
    return API.post("usdk-promo-fund-transfer", JSON.stringify(data))
      .then((response) => Promise.resolve(response))
      .catch((error) => Promise.reject(error));
  },
};
