import { Form } from 'react-bootstrap';
import CurrencyInput from 'react-currency-input-field';
import React, { useEffect, useState } from 'react';

const defaultFunction = (({...args}) => args);

export const WalletAmountInput = ({wallet, fieldName, fieldValue, disabled=false, errorMessage=defaultFunction, onHandleChange=defaultFunction, t}) => {
    const [width, setWidth] = useState(60);
    // fieldValue = Math.round(fieldValue*100)/100;

    if(fieldValue) {
        fieldValue = Math.round(fieldValue*100)/100;
    }

    useEffect(() => {
        setWidth(t(wallet).length * 11 +24);
    }, [wallet]);

    return <div className="amount-field input-group">
        <Form.Control as="select" className="custom-width" disabled style={{
            width: `${width}px`,
            maxWidth: `${width}px`, // Enforce the width
            minWidth: `${width}px`, // Enforce the width
        }}>
            <option value="">{t(wallet)}</option>
        </Form.Control>
        <CurrencyInput
            name={fieldName}
            id={fieldName}
            placeholder={t(fieldName)}
            value={fieldValue}
            onValueChange={(value, id) =>
                onHandleChange({target: {value, id}})
            }
            required
            allowNegativeValue={false}
            decimalsLimit={2}
            disabled={disabled}
        />
        <div className="number-field-invalid-feedback">
            {errorMessage.amount}
        </div>
    </div>
}
