import React from 'react';
import { useTranslation } from "react-i18next";
import { TableSkeleton } from '../TableSkeleton';
import { TableContentNoDataFound } from './TableContentNoDataFound';
import CurrencyConverter from '../../../../Currency/CurrencyConverter';
import { formatDate } from '../../../../utils/formateDate';
import { useSelector } from 'react-redux';
import { BalanceColumn } from './BalanceColumn';

export const StatementContentTable = ({ type, data }) => {
    const userSelectedCurrency = useSelector(
        (state) => state.user?.selectedCurrency
    );
    const conversionFactor = useSelector(
        (state) => state?.user?.conversionFactor
    );
    const {t} = useTranslation();

    if(!data) {
        return (
            <tbody>
            <TableSkeleton rowCount={6} cellCount={6}/>
            </tbody>
        )
    }

    if(data?.length === 0) {
        return <TableContentNoDataFound></TableContentNoDataFound>
    }


    return (

        <tbody>

        {data?.map((row, rowIndex) => {
            return (
                <tr key={`tr-${type}-${rowIndex}`}>
                    {row.ewalletType === "commission" && (
                        <td>
                            <div className="profile_table">
                                <img src="/images/icons-money-bag.png" alt="" />
                            </div>
                            {`${t(row.amountType)} ${row.fromUser ? t("from") : ""} ${
                                row.fromUser ? row.fromUser.toUpperCase() : ""
                            }`}
                        </td>
                    )}
                    {row.ewalletType === "fund_transfer" && (
                        <td>
                            <div className="profile_table">
                                <img src="/images/icons-transfer.png" alt="" />
                            </div>
                            {`${t(row.amountType)} ${
                                row.type === "credit"
                                    ? row.fromUser ?? ""
                                    : row.toUser ?? ""
                            } ${
                                row.transactionFee !== 0
                                    ? ` (${t("transactionFee")} : ${t(type)} ${row.transactionFee})`
                                    : ""
                            }`}
                        </td>
                    )}
                    {row.ewalletType === "ewallet_payment" && (
                        <td>
                            <div className="profile_table">
                                <img src="/images/icons-credit-card.png" alt="" />
                            </div>
                            {`${t(row.amountType)}`}
                        </td>
                    )}
                    {row.ewalletType === "payout" && (
                        <td>
                            <div className="profile_table">
                                <img src="/images/icons-withdrawal.png" alt="" />
                            </div>
                            {`${t(row.amountType)}`}
                        </td>
                    )}
                    {row.totalAmount && (
                        <td>
                              <span className="up_ewallet">
                                {`${t(type)} ${CurrencyConverter(
                                    row.totalAmount,
                                    conversionFactor
                                )} `}
                                  <i className="fa fa-arrow-up"></i>
                              </span>
                        </td>
                    )}
                    {row.tds && (
                        <td>{`${t(type)} ${CurrencyConverter(
                            row.tds,
                            conversionFactor
                        )}`}</td>
                    )}
                    {(row.serviceCharge || row.serviceCharge === 0) && (
                        <td>{`${t(type)} ${CurrencyConverter(
                            row.serviceCharge,
                            conversionFactor
                        )}`}</td>
                    )}
                    {row.amountPayable && (
                        <td>
                      <span className="up_ewallet">
                        {`${t(type)} ${CurrencyConverter(
                            row.amountPayable,
                            conversionFactor
                        )} `}
                          <i className="fa fa-arrow-up"></i>
                      </span>
                        </td>
                    )}
                    {row.amount && (
                        <td>
                            {row.type === "credit" ? (
                                <span className="up_ewallet">
                          {`${t(type)} ${CurrencyConverter(
                              row.amount,
                              conversionFactor
                          )} `}
                                    <i className="fa fa-arrow-up"></i>
                        </span>
                            ) : (
                                <span className="down_ewallet">
                          {`${t(type)} ${CurrencyConverter(
                              row.amount,
                              conversionFactor
                          )} `}
                                    <i className="fa fa-arrow-down"></i>
                        </span>
                            )}
                        </td>
                    )}
                    {row.dateAdded && <td>{formatDate(row.dateAdded)}</td>}
                    {/* {row.balance && (
                        <BalanceColumn type={type} amount={row.balance}></BalanceColumn>
                    )} */}
                </tr>
            )}
        )}
        </tbody>
    )
}

