import React from 'react';
import { useTranslation } from "react-i18next";

export const ShopRegisterFormWizardRow = ({steps, activeStep}) => {
    const { t } = useTranslation();

    return <div className="row justify-content-center pt-0 p-4" id="wizardRow">
        <div className="col-md-12 text-center">
            <div className="wizard-form py-4 my-2">
                <ul id="progressBar" className="progressbar px-lg-5 px-0">
                    {steps?.map((step, index) => (
                        <li
                            key={`step-${index + 1}`}
                            id={`progressList-${index + 1}`}
                            className={`d-inline-block w-20 position-relative text-center float-start progressbar-list ${activeStep - 1 >= step.step ? "active" : ""}`}
                            style={{ width: "33.33%" }}
                        >
                            {t("step")}
                            {step.step}
                        </li>
                    ))}
                </ul>
            </div>
        </div>
    </div>
};

