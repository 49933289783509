import { useQuery } from '@tanstack/react-query';
import { TopEarners } from '../../../store/actions/dashboardAction';

export const CallTopEarners = (earnersCheck, setEarnersCheck, startDate, endDate) => {
    const response = useQuery({
        queryKey: ["top-earners", startDate, endDate],
        queryFn: () => TopEarners(startDate, endDate),
        onSuccess: ({data}) => {
            setEarnersCheck(false);
            return data; },

        enabled: !!earnersCheck,
    });
    return response;
}
