import { useState, useEffect } from "react";
import { Modal, Button } from "react-bootstrap";
import { ApiHook } from "../../hooks/apiHook";

const Popup = ({ data = [] }) => {
  const [show, setShow] = useState(false);
  const [currentIndex, setCurrentIndex] = useState(0);
  const endTutorialMutation = ApiHook.CallMarkPopup();

  useEffect(() => {
    // Check if the data has already been viewed (stored in localStorage)
    const viewedPopups = JSON.parse(localStorage.getItem("viewedPopups")) || [];
    const unseenPopups = data.filter((item) => !viewedPopups.includes(item.id));

    if (unseenPopups.length > 0) {
      setShow(true);
    } else {
      setShow(false);
    }
  }, [data]);

  useEffect(() => {
    if (show && data[currentIndex]?.id) {
      // Mark the current item as viewed
      const viewedPopups =
        JSON.parse(localStorage.getItem("viewedPopups")) || [];
      if (!viewedPopups.includes(data[currentIndex].id)) {
        localStorage.setItem(
          "viewedPopups",
          JSON.stringify([...viewedPopups, data[currentIndex].id])
        );
      }
    }
  }, [show, currentIndex, data]);

  const markCurrentAsRead = async () => {
    const currentId = data[currentIndex]?.id;
    if (currentId) {
      endTutorialMutation.mutate({ logId: currentId });
    }
  };

  const hide = async () => {
    await markCurrentAsRead();
    setShow(false);
  };

  const handleNext = async () => {
    await markCurrentAsRead();
    console.log({ currentIndex });
    console.log("currentIndex");
    if (currentIndex < data.length - 1) {
      setCurrentIndex((prevIndex) => prevIndex + 1);
    }
  };

  const handlePrevious = () => {
    if (currentIndex > 0) {
      setCurrentIndex((prevIndex) => prevIndex - 1);
    }
  };

  const openUrl = () => {
    if (data[currentIndex]?.url) {
      window.open(data[currentIndex].url, "_blank", "noopener,noreferrer");
    }
  };

  return (
    <Modal id="popup" show={show} onHide={hide} size="lg" centered>
      <Modal.Header closeButton>
        <Modal.Title className="w-100 text-center">
          {data[currentIndex]?.title || "No Title"}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="d-flex flex-column align-items-center text-center">
        {data[currentIndex]?.image && (
          <img
            src={data[currentIndex]?.image}
            alt={data[currentIndex]?.title || "No Title"}
            className="img-fluid mb-3"
            style={{ maxWidth: "100%", height: "auto" }}
          />
        )}

        <p>{data[currentIndex]?.description || "No Description"}</p>
        {data[currentIndex]?.active === 1 && data[currentIndex]?.url && (
          <button onClick={openUrl} className="btn btn-link">
            Open URL
          </button>
        )}
      </Modal.Body>

      <Modal.Footer className="justify-content-center">
        {data.length > 1 && (
          <>
            {currentIndex > 0 && (
              <Button variant="secondary" onClick={handlePrevious}>
                Previous
              </Button>
            )}
            {currentIndex < data.length - 1 && (
              <Button variant="primary" onClick={handleNext}>
                Next
              </Button>
            )}
          </>
        )}
        <Button variant="danger" onClick={hide}>
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default Popup;
