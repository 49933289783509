import React from "react";
import { cutText } from "../../utils/helpers.ts";

interface ProductItemProps {
  badgeText?: string;
  productImage: string;
  title: string;
  description: string;
  price: number;
  priceTag?: string;
  onViewDetails: () => void;
  comingSoon?: boolean;
}

const ProductItem: React.FC<ProductItemProps> = ({
  badgeText = "NEW",
  productImage,
  title,
  description,
  price,
  priceTag,
  onViewDetails,
  comingSoon,
}) => {
  return (
    // <div className="max-w-[264px] relative">
    // <div className="w-[30%] relative">
    <div className="relative max-w-[300px] flex-shrink-0 basis-1/3 ">
      <div
        className={`
          bg-white 
          rounded-xl 
          p-6 
          border 
          border-gray-300 
          transition-transform 
          duration-300 
          ease-in-out 
          min-h-[26rem]
          ${comingSoon ? "shadow-[0_0_10px_rgba(255,215,0,0.8)] animate-pulse" : "hover:shadow-lg hover:shadow-blue-500 hover:scale-105"}
        `}
      >
        <div className="relative -mt-16 right-10">
          <img
            src={productImage}
            alt="Product Box"
            className="w-48 h-48 object-contain border-gray-200"
          />
        </div>

        {badgeText && (
          <div className="absolute rounded-full h-14 w-14 items-center text-center justify-center flex top-4 right-4 bg-blue-600 text-white text-xs font-semibold px-2 py-1 uppercase">
            {badgeText}
          </div>
        )}

        <div className="mt-8">
          <h2 className="text-lg font-bold text-primary mb-2">{title}</h2>

          <p className="text-sm text-gray-500 mb-2">
            {cutText(description, 50)}
          </p>

          {/* {freeText && (
            <span className="text-xs text-gray-600 italic">{freeText}</span>
          )} */}
        </div>

        <div className="flex items-end justify-between mt-12 ">
            <button
            onClick={comingSoon ? undefined : onViewDetails}
            disabled={comingSoon}
            className={`
              ${comingSoon ? "bg-gray-400 cursor-not-allowed" : "bg-blue-600 hover:bg-blue-700"} 
              text-white 
              font-medium 
              py-2 
              px-4 
              rounded 
              transition-colors
              text-xs
            `}
            >
            { comingSoon? "Coming soon": "View Details" }
          </button>
          <div className="flex flex-col text-end">
            {price && <span className="text-sm font-bold text-gray-900">${price}</span>}
            {priceTag && (
              <span className="text-[10px] text-gray-600 italic">
                /{priceTag}
              </span>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProductItem;
