import React, { useState, useEffect, useRef } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { updateConversionFactors } from "../../store/reducers/userReducer";
import { useDispatch, useSelector } from "react-redux";
import { ApiHook } from "../../hooks/apiHook";
import { useTranslation } from "react-i18next";
import { formatDate } from "../../utils/formateDate";
import Skeleton from "react-loading-skeleton";
import { useQueryClient } from "@tanstack/react-query";

const HeaderSection = ({
  count,
  handleLeftMenuToggle,
  userName,
  appLayout,
  toggleMobileRef,
}) => {
  const queryClient = useQueryClient();
  const [unreadCount, setUnreadCount] = useState(count !== undefined ? count : 0);
  const userSelectedCurrency = useSelector(
    (state) => state.user?.selectedCurrency
  );
  const userSelectedLanguage = useSelector(
    (state) => state.user?.selectedLanguage
  );
  const mailUnreadCount = useSelector(
    (state) => state.mail.unReadCount
  );
  const [notificationCheck, setNotificationCheck] = useState(false);
  const moduleStatus = appLayout?.moduleStatus;
  const [dropdownOpen, setDropdownOpen] = useState({
    currency: false,
    country: false,
    notification: false,
    user: false,
  });
  const [selectedCurrency, setSelectedCurrency] = useState({
    id: null,
    symbolLeft: null,
    code: null,
    value: null,
  });
  const [selectedLanguage, setSelectedLanguage] = useState({
    id: null,
    flagImage: null,
    code: null,
    name: null,
  });

  // API CALLS
  const logoutMutation = ApiHook.CallLogout();
  const updateCurrencyMutation = ApiHook.CallCurrencyUpdation({
    selectedCurrency,
  });
  const updateLanguageMutation = ApiHook.CallLanguageUpdation({
    selectedLanguage,
  });
  const notificationData = ApiHook.CallNotificationData(
    notificationCheck,
    setNotificationCheck
  );
  const readAllNotification = ApiHook.CallReadAllNotification();
  const readNotificationMutation = ApiHook.CallReadNotification();

  const dropdownCurrencyRef = useRef(null);
  const dropdownCountryRef = useRef(null);
  const dropdownNotificationRef = useRef(null);
  const dropdownUserRef = useRef(null);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const { t, i18n } = useTranslation();

  const toggleDropdown = (dropdown) => {
    if (dropdown === "notification") {
      setNotificationCheck(true);
    }
    setDropdownOpen((prevState) => ({
      currency: dropdown === "currency" ? !prevState.currency : false,
      country: dropdown === "country" ? !prevState.country : false,
      notification:
        dropdown === "notification" ? !prevState.notification : false,
      user: dropdown === "user" ? !prevState.user : false,
    }));
  };
  useEffect(() => {
    if (location.pathname) {
      setDropdownOpen(false);
    }
    const handleOutsideClick = (event) => {
      const dropdownRefs = [
        dropdownCurrencyRef,
        dropdownCountryRef,
        dropdownNotificationRef,
        dropdownUserRef,
      ];
      const isClickInsideDropdown = dropdownRefs.some(
        (ref) => ref.current && ref.current.contains(event.target)
      );
      if (!isClickInsideDropdown) {
        setDropdownOpen({
          currency: false,
          country: false,
          notification: false,
          user: false,
        });
      }
    };

    document.addEventListener("click", handleOutsideClick);

    return () => {
      document.removeEventListener("click", handleOutsideClick);
    };
  }, [location.pathname]);

  const dropdownCurrencies = appLayout?.currencies;

  const dropdownCountries = appLayout?.languages;

  const handleLogout = async () => {
    logoutMutation.mutate();
  };

  const changeCurrency = (currency) => {
    const newCurrency = {
      currencyId: currency?.id.toString(),
    };
    setSelectedCurrency({
      id: currency?.id,
      symbolLeft: currency?.symbolLeft,
      value: currency?.value,
      code: currency?.code,
    });
    updateCurrencyMutation.mutateAsync(newCurrency);

    // update conversionFactor
    dispatch(updateConversionFactors(currency));
    setDropdownOpen({ currency: false });
  };
  const changeLanguage = (language) => {
    const newLanguage = { langId: language?.id.toString() };
    setSelectedLanguage({
      id: language?.id,
      flagImage: language?.flagImage,
      code: language?.code,
      name: language?.name,
    });
    updateLanguageMutation.mutateAsync(newLanguage);
    setDropdownOpen({ country: false });
    i18n.changeLanguage(language?.code);
  };
  const handleReadAll = () => {
    readAllNotification.mutateAsync(null, {
      onSuccess: (res) => {
        if (res?.status) {
          notificationData.data.data = [];
        }
      }
    });
  };
  const readSingleNotification = (id) => {
    // const payload = { id : id};
    readNotificationMutation.mutateAsync(id, {
      onSuccess: (res) => {
        if (res?.status) {
          setUnreadCount(prevCount => prevCount - 1);
          setNotificationCheck(true);
          queryClient.invalidateQueries({ queryKey: ["notification-data"] });
        }
      }
    });
  }
  useEffect(() => {
  if (count !== undefined) {
    setUnreadCount(count);
  }
}, [count]);

  return (
    <header className="header_section">
      <div className="mx-10" >
      <div className="row bg-[#1B233E] rounded-full">
        <div className="col-md-4 col-6">
          <div className="leftLogo_section justify-center flex flex-col ">
            <Link to={"/dashboard"} >
              <img
                // src={"/img/logos/sellex-logo-170.jpeg"}
                src={"/images/sellex_logo.svg"}
                onClick={() => navigate("/shopping")}
                alt=""
              />
            </Link>
          </div>
        </div>
        <div className="col-md-8 col-6">
          <div className="right_notiifcation_mail_ico_sec">
            {moduleStatus?.multi_currency_status === 123 && (
              <div
                className={`right_notiifcation_mail_ico top_dropdown currency_dropdown ${dropdownOpen.currency ? "show" : ""
                  }`}
                ref={dropdownCurrencyRef}
              >
                <Link
                  className="dropdown-toggle"
                  onClick={() => toggleDropdown("currency")}
                  data-bs-toggle="dropdown"
                  aria-expanded={dropdownOpen.currency}
                >
                  <span className="currency_top_slctd">
                    {userSelectedCurrency?.symbolLeft}
                  </span>
                </Link>
                <div
                  className={`dropdown-menu usr_prfl right-0 animation slideDownIn ${dropdownOpen.currency ? "show" : ""
                    }`}
                >
                  <div className="usr_prfl_setting">{t("currency")}</div>
                  <ul className="">
                    {dropdownCurrencies?.map((item, index) => (
                      <li key={index}>
                        <Link
                          className="dropdown-item"
                          onClick={() => changeCurrency(item)}
                        >
                          <span>{item.symbolLeft}</span> {item.title}
                        </Link>
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
            )}
            {moduleStatus?.multilang_status === 1 && (
              <div
                className={`right_notiifcation_mail_ico top_dropdown country_dropdown ${dropdownOpen.country ? "show" : ""
                  }`}
                ref={dropdownCountryRef}
              >
                <Link
                  className="dropdown-toggle"
                  onClick={() => toggleDropdown("country")}
                  data-bs-toggle="dropdown"
                  aria-expanded={dropdownOpen.country}
                >
                  <img src={`/${userSelectedLanguage?.flagImage}`} alt="" />
                </Link>
                <div
                  className={`dropdown-menu usr_prfl right-0 animation slideDownIn ${dropdownOpen.country ? "show" : ""
                    }`}
                >
                  <div className="usr_prfl_setting">{t("language")}</div>
                  <ul className="">
                    {dropdownCountries?.map((item, index) => (
                      <li key={index}>
                        <Link
                          className="dropdown-item"
                          onClick={() => changeLanguage(item)}
                        >
                          <img src={`/${item?.flagImage}`} alt="" /> {item.name}
                        </Link>
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
            )}
            <div
              className={`right_notiifcation_mail_ico user_avatar ${dropdownOpen.user ? "show" : ""
                }`}
              ref={dropdownUserRef}
            >
              <Link
                href="#"
                data-tut="profile"
                className="dropdown-toggle"
                data-bs-toggle="dropdown"
                aria-expanded={dropdownOpen.user}
                onClick={() => toggleDropdown("user")}
              >
                <img
                  src={
                    appLayout?.user?.image
                      ? appLayout?.user?.image
                      : "/images/user-profile.png"
                  }
                  alt=""
                />
              </Link>
              <div
                className={`dropdown-menu usr_prfl right-0 animation slideDownIn ${dropdownOpen.user ? "show" : ""
                  }`}
              >
                <div className="usr_prfl_setting">{userName}</div>
                <ul className="">
                  {/* <li key="profile">
                    <Link to={"/profile"} className="dropdown-item">
                      {t("profile")}
                    </Link>
                  </li> */}
                  <li key="logout">
                    <Link className="dropdown-item" onClick={handleLogout}>
                      {t("logout")}
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      </div>
    </header>
  );
};

export default HeaderSection;
