import { useQuery } from '@tanstack/react-query';
import { TopRecruiters } from '../../../store/actions/dashboardAction';

export const CallTopRecruiters = (recruitersCheck, setRecruitersCheck, startDate, endDate) => {
    const response = useQuery({
        queryKey: ["top-recruiters", startDate, endDate],
        queryFn: () => TopRecruiters(startDate, endDate),
        onSuccess: ({data}) => {
            setRecruitersCheck(false);
            return data;
        },
        enabled: !!recruitersCheck,
    });
    return response;
}
