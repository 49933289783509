import './FilterDropdown.css';
import dayjs from 'dayjs';
import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from 'react-i18next';
import { Chevron } from './Chevron';
import { DatePicker } from "antd";
const { RangePicker } = DatePicker;

export const FilterDropdown = ({title, filters, onFilterChange, classNames}) => {
    const defaultFilter = filters.find(({isDefault}) => isDefault);
    const [selectedFilter, setSelectedFilter] = useState(defaultFilter);
    const [isOpen, setIsOpen] = useState(false);
    const [startDate, setStartDate] = useState(null, null);
    const [endDate, setEndDate] = useState(null, null);

    const {t} = useTranslation();
    const dropdownRef = useRef(null);
    const buttonRef = useRef(null);

    const handleSelect = (filter) => {
        setSelectedFilter(filter);
        setStartDate(filter.startDate);
        setEndDate(filter.endDate);
        setIsOpen(false);
        onFilterChange && onFilterChange({...filter});
    };

    const handleDateRangeChange = (dates) => {
        const newStartDate = Boolean(dates) && dates[0].format("YYYY-MM-DD");
        const newEndDate = Boolean(dates) && dates[1].format("YYYY-MM-DD");
        if(!newStartDate || !newEndDate) {
            handleSelect({ name: 'historical' });
        } else {
            handleSelect({ startDate: newStartDate, endDate: newEndDate, name: 'custom' });
        }
    };

    useEffect(() => {
        setIsOpen(false);

        const handleOutsideClick = (event) => {
            if (
                dropdownRef.current &&
                !dropdownRef.current.contains(event.target) &&
                buttonRef.current &&
                !buttonRef.current.contains(event.target)
            ) {
                setIsOpen(false);
            }
        };
        document.addEventListener("click", handleOutsideClick);
        return () => {
            document.removeEventListener("click", handleOutsideClick);
        };
    }, []);

    useEffect(() => {
        if (isOpen && dropdownRef.current && buttonRef.current) {
            const buttonRect = buttonRef.current.getBoundingClientRect();
            const dropdown = dropdownRef.current;
            dropdown.style.top = `${buttonRect.bottom + window.scrollY}px`;
            dropdown.style.left = `${buttonRect.left + window.scrollX}px`;
            dropdownRef.current.style.width = `${buttonRef.current.offsetWidth}px`;
        }
    }, [isOpen]);

    return (
        <div className={`filterDropDown ${classNames}`}>
            {/* Title for the dropdown */}
            {title && <label className="block mb-1">{t(title)}:</label>}

            <div className="d-flex border rounded overflow-hidden">
                {/* Filter Button Styled Like an Input */}
                <div
                    ref={buttonRef}
                    onClick={() => setIsOpen(!isOpen)}
                    className="d-flex align-items-center px-3 bg-white border-end border-light-grey"
                    role="button"
                >
                    <span>{t(selectedFilter?.name)}</span>
                    <Chevron className="ms-2"></Chevron>
                </div>

                {/* Range Picker Styled to Match */}
                <RangePicker
                    value={startDate && endDate ? [dayjs(startDate), dayjs(endDate)] : null}
                    onChange={handleDateRangeChange}
                    format="YYYY-MM-DD"
                    className="flex-grow-1 range-picker-border"
                />
            </div>

            {/* Dropdown Menu - Absolutely positioned outside layout */}
            {isOpen && (
                <div
                    ref={dropdownRef}
                    className={`filter-dropdown-menu text-start`}
                >
                    <ul className="py-1 list-unstyled option-list">
                        {filters.map((filter) => (
                            <li key={filter.name} role={`button`} onClick={() => handleSelect(filter)}
                                className={`filter-dropdown-item`}>
                                {t(filter.name)}
                            </li>
                        ))}
                    </ul>
                </div>
            )}
        </div>
    );
};


