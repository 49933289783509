import React, { useEffect, useState } from "react";
import Select from "react-select";
import { Form } from 'react-bootstrap';
import PropTypes from "prop-types";
import { downlineMembersActions } from '../../../store/actions/treeAction';
import { useTranslation } from "react-i18next";

const UserSelect = ({ value, onChange, errorMessage }) => {
    const { t } = useTranslation();
    const [downlineMembers, setDownlineMembers] = useState([]);

    useEffect(() => {
        const fetchMembers = async () => {
            try {
                const members = await downlineMembersActions.getDownlineMembers("all", 0, 1000);
                const dlMembers = members.data.data.map(({ username, fullName }) => ({
                    value: username,
                    label: username,
                    // label: fullName,
                }));
                setDownlineMembers(dlMembers);
            } catch (error) {
                console.error("Error fetching members:", error);
            }
        };

        fetchMembers();
    }, []);

    return (
        <Form.Group className="mb-3">
            <Form.Label>{t("transferToUsername")} *</Form.Label>
            <Select
                id="username"
                placeholder="Transfer To (Username)"
                options={downlineMembers}
                onChange={(selectedOption) => {
                    onChange({ target: { id: "username", value: selectedOption?.value } });
                }}
                value={downlineMembers.find((option) => option.value === value)}
                isClearable
                styles={{
                    control: (base) => ({
                        ...base,
                        borderColor: errorMessage ? "red" : base.borderColor,
                    }),
                }}
            />
            {errorMessage && (
                <div className="invalid-feedback" style={{ display: "block" }}>
                    {errorMessage}
                </div>
            )}
        </Form.Group>
    );
};

UserSelect.propTypes = {
    value: PropTypes.string,
    onChange: PropTypes.func.isRequired,
    errorMessage: PropTypes.string,
};

export default UserSelect;
