import React, { useState } from "react";
import { ApiHook } from "../../hooks/apiHook";
import { useSelector } from "react-redux";
import BalancesViewNavbar from '../../components/Balances/BalancesViewNavbar';
import OptionButtonsBar from '../../components/Balances/OptionButtonsBar';
import { WalletTransferOffCanvas } from '../../components/Wallet/CommonComponents/WalletTransferOffCanvas';
import { WalletTiles } from '../../components/Wallet/CommonComponents/WalletTiles';
import { WalletTable } from '../../components/Wallet/CommonComponents/WalletTable';

const MainUsdkPromoWallet = () => {
  const [showTransfer, setShowTransfer] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);

  const handleEwalletTransfer = () => {
    setShowTransfer((prevShowTransfer) => !prevShowTransfer);
  };
  const ewallet = ApiHook.CallUSDKPromowalletTiles();
  const userSelectedCurrency = useSelector(
    (state) => state.user?.selectedCurrency
  );
  const conversionFactor = useSelector(
    (state) => state?.user?.conversionFactor
  );

    const optionButtons = [
        // { title: 'usdk_promo_fund_transfer', handleOpen: handleEwalletTransfer, type: 'ewallet' }
    ];

  return (
      <>
          <div className="navbar-wrapper mb-2">
              <BalancesViewNavbar/>
              <OptionButtonsBar buttons={optionButtons}/>
          </div>
          <WalletTiles
              wallet={`usxp`}
              tiles={ewallet?.data}
              currency={userSelectedCurrency}
              conversionFactor={conversionFactor}
          >
          </WalletTiles>
          <WalletTable
              wallet={`usxp`}
              currentPage={currentPage}
              setCurrentPage={setCurrentPage}
              currency={userSelectedCurrency}
          >
          </WalletTable>
          <WalletTransferOffCanvas
              balance={ewallet?.data?.balance}
              show={showTransfer}
              handleClose={handleEwalletTransfer}
              fromWallet={`usxp`}
          />
      </>
  );
};

export default MainUsdkPromoWallet;
