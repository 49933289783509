import React, { useEffect } from "react";
import { ApiHook } from "../../hooks/apiHook";
import { useQueryClient } from "@tanstack/react-query";
import { toast } from "react-toastify";

type ProductModalProps = {
  cartId: string;
  image: string;
  title: string;
  productTag: string;
  description: string;
  price: number;
  priceTag: string;
  visible: boolean;
  onClose: () => void;
};

function ProductModal({
  cartId,
  visible,
  onClose,
  image,
  title,
  productTag,
  description,
  price,
  priceTag,
}: ProductModalProps) {
  const queryClient = useQueryClient();
  const addCartMutation = ApiHook.CallAddToCart();

  useEffect(() => {
    if (visible) {
      document.body.style.overflow = "hidden"; // Deshabilita el scroll
    } else {
      document.body.style.overflow = "auto"; // Restaura el scroll
    }

    return () => {
      document.body.style.overflow = "auto"; // Limpieza por si el componente se desmonta
    };
  }, [visible]);

  const handleCart = () => {
    const data: any = {
      packageId: cartId,
    };
    addCartMutation.mutate(data, {
      onSuccess: (res) => {
        onClose();
        if (res?.status) {
          queryClient.invalidateQueries({ queryKey: ["cart-items"] });
        } else {
          if (res?.data?.code) {
            toast.error(res?.data?.description);
          } else {
            toast.error(res?.message);
          }
        }
      },
    });
  };

  return (
    <>
      {visible && (
        <div
          // className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center"
          className="fixed inset-0 flex items-center justify-center"
          style={{ zIndex: 1000 }}
          onClick={onClose}
        >
          <div
            className="bg-[white] rounded-xl shadow-lg max-w-5xl w-full min-h-[70%] relative flex flex-col justify-between pb-8 "
            onClick={(e) => e.stopPropagation()}
          >
            <div>
              <div
                style={{
                  background: "url('images/pop-up-bg.png') no-repeat",
                  backgroundSize: "cover",
                  borderTopLeftRadius: "1rem",
                  borderTopRightRadius: "1rem",
                }}
                className="py-8 bg-cover bg-center"
              >
                <button
                  onClick={onClose}
                  className="absolute -top-10 right-0 text-gray-500 hover:text-gray-800 bg-white rounded-full h-8 w-8"
                >
                  ✕
                </button>
                <div className="absolute -top-16 left-1/2 transform -translate-x-1/2">
                  <img
                    // src="images/s1.png"
                    src={image}
                    alt="Product"
                    className="w-64 h-64 object-cover"
                  />
                  <div className="absolute bottom-8 right-4 bg-blue-500  px-2 py-1 rounded-full flex items-center justify-center h-[76px] w-[76px]">
                    <span className="text-white text-xs font-bold text-center ">
                      {productTag.toUpperCase()}
                    </span>
                  </div>
                </div>
                <div className="h-24" />
              </div>

              <div className="px-6 py-8 flex flex-col items-center max-w-2xl justify-center mx-auto">
                <h2 className="text-xl font-bold text-primary mb-2">{title}</h2>

                {/* <p className="bg-primary p-2 text-xs font-medium text-white mb-4 w-fit rounded-md">
                  Subscription for Distributors
                </p> */}

                <p className="text-sm text-gray-500 leading-relaxed mb-6">
                  {description}
                </p>

                {/* <div className="flex items-center gap-4 justify-center ">
                <button
                  className="
                    bg-blue-600 
                    text-white 
                    font-semibold 
                    py-2 
                    px-6 
                    rounded-lg 
                    hover:bg-blue-700 
                    transition-colors
                  "
                >
                  Purchase now
                </button>
                <span className="text-lg font-bold text-gray-800">
                  ${price}
                  <span className="text-sm text-gray-500">/{priceTag}</span>
                </span>
              </div> */}
              </div>
            </div>
            <div className="flex items-center gap-4 justify-center ">
              <button
                className="
                    bg-blue-600 
                    text-white 
                    font-semibold 
                    py-2 
                    px-6 
                    rounded-lg 
                    hover:bg-blue-700 
                    transition-colors
                  "
                disabled={addCartMutation.isLoading}
                onClick={handleCart}
              >
                Purchase now
              </button>
              <span className="text-lg font-bold text-gray-800">
                ${price}
                {priceTag && (
                  <span className="text-sm text-gray-500">/{priceTag}</span>
                )}
              </span>
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default ProductModal;
