import axios from "axios";
import { RSAencrypt } from "./helpers.ts";

const domains = {
  dev: "https://xapixeradev.xssclients.com",
  stage: "https://xapixerastg.xssclients.com",
  prod: "https://xapi.myxpro.app",
  local: "https://xapixeradev.xssclients.com",
};

const env = process.env.REACT_APP_ENV || "dev";

const domain = domains[env];

const login = async (email: string, password: string, captcha: string) => {
  try {
    const encryptedPassword = RSAencrypt(password);
    const request = await axios.post(`${domain}/api/v2.0/LogIn`, {
      email,
      password: encryptedPassword,
      captcha,
    });
    return request.data;
  } catch (error) {
    throw error;
  }
};

const otherBackoffice = {
  login,
};

export default otherBackoffice;
