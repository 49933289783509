import React, { useState } from "react";
import { Offcanvas, Form } from "react-bootstrap";
import { useQueryClient } from "@tanstack/react-query";
import { handleChange, invalidateQueries, handleQueryError } from '../walletHelper';
import SubmitButton from "../../Common/buttons/SubmitButton";
import { useTranslation } from "react-i18next";
import UserSelect from './UserSelect';
import { getWalletConfig } from '../walletConfigs';
import { WalletAmountInput } from './WalletAmountInput';

export const WalletTransferOffCanvas = ({ balance, show, handleClose, fromWallet }) => {
    const { t } = useTranslation();
    const [transferData, setTransferData] = useState({ username: "", amount: "" });
    const [errorMessage, setErrorMessage] = useState({ username: null, amount: null });

    const walletConfig = getWalletConfig(fromWallet);
    const transferMutation = walletConfig.transferHook();
    const queryClient = useQueryClient();

    const onHandleChange = (e) => {
        return handleChange(e, setTransferData, setErrorMessage);
    }

    const clearData = () => {
        setErrorMessage({ username: null, amount: null });
        setTransferData({ username: "", amount: "" });
    }

    const isFormValid = () => {
        return (
            transferData?.username.trim() !== "" &&
            transferData?.amount > 0
        );
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (!isFormValid()) {
            return;
        }
        try {
            const postData = { username: transferData?.username, amount: transferData?.amount };
            await transferMutation.mutateAsync(postData, {
                onSuccess: (res) => {
                    if (res.status === 200) {
                        clearData();
                        invalidateQueries(queryClient, walletConfig.queries);
                        handleClose(false);
                    } else {
                        handleQueryError(res, setErrorMessage, t);
                    }
                },
            });
        } catch (error) {
            // Handle general error or network issue
            console.error("Error submitting transfer:", error);
        }
    };

    return (
        <Offcanvas
            show={show}
            onHide={() => {
                clearData();
                handleClose();
            }}
            placement="end"
            id="ewalletTransfer"
        >
            <Offcanvas.Header closeButton>
                <Offcanvas.Title>{`${t(fromWallet)} ${t("fund_transfer")}`}</Offcanvas.Title>
            </Offcanvas.Header>
            <Offcanvas.Body>
                <Form>
                    <UserSelect
                        value={transferData.username}
                        onChange={(e) => onHandleChange(e)}
                        errorMessage={errorMessage?.username}
                    />
                    <Form.Group className="mb-3">
                        <Form.Label>{t("amount")}<span>*</span></Form.Label>
                        <WalletAmountInput fieldName={`amount`} wallet={fromWallet} fieldValue={transferData.amount} errorMessage={errorMessage} onHandleChange={onHandleChange} t={t}></WalletAmountInput>
                    </Form.Group>
                    <Form.Group className="mb-3">
                        <Form.Label>{t("availableAmount")}</Form.Label>
                        <WalletAmountInput fieldName={``} wallet={fromWallet} fieldValue={balance} t={t} disabled={true}></WalletAmountInput>
                    </Form.Group>
                    <SubmitButton
                        isSubmitting={!isFormValid() || transferMutation.isLoading}
                        click={handleSubmit}
                        text="submit"
                        className={"btn btn-primary submit_btn"}
                        isLoading={transferMutation.isLoading}
                    />
                </Form>
            </Offcanvas.Body>
        </Offcanvas>
    );
};
