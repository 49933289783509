import React, { Fragment, useEffect, useState, useRef } from "react";
import { useForm } from "react-hook-form";
import Select from "react-select";
import {
  loginPasswordValidator,
  validateAge,
  loginUsernameValidator,
  passwordRules,
} from "../../Validator/register";
import PasswordChecklist from "react-password-checklist";
import { ApiHook } from "../../hooks/apiHook";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import CurrencyConverter from "../../Currency/CurrencyConverter";
import { PhoneInput } from "react-international-phone";
import "react-international-phone/style.css";
import DatePickerComponent from "../Common/DatePickerComponent";
import dayjs from "dayjs";
import Visibility from "@mui/icons-material/Visibility";
import InputAdornment from "@mui/material/InputAdornment";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import IconButton from "@mui/material/IconButton";
import HCaptcha from "@hcaptcha/react-hcaptcha";
import { SITEKEY } from "../../config/config";
import { ShopRegisterFormWizardRow } from './ShopRegisterFormCoponents/ShopRegisterFormWizardRow';
import { ShopRegisterFormLoader } from './ShopRegisterFormCoponents/ShopRegisterFormLoader';
import { ShopRegisterFormStep4 } from './ShopRegisterFormCoponents/ShopRegisterFormStep4';
import { ShopRegisterFormStep2 } from './ShopRegisterFormCoponents/ShopRegisterFormStep2';
import { ShopRegisterFormStep3 } from './ShopRegisterFormCoponents/ShopRegisterFormStep3';

const ShopRegisterForm = ({
  activeStep,
  setActiveStep,
  handleToggleRegisterModal,
  animateStepTransition,
  data,
  placement,
  position,
}) => {
  const {
    register,
    setValue,
    setError,
    trigger,
    watch,
    getValues,
    formState: { errors },
  } = useForm();

  const [submitButtonActive, setSubmitButtonActive] = useState(true);

  const [states, setStates] = useState([]);
  const [selectedGender, setSelectedGender] = useState();
  const [phoneNumber, setPhoneNumber] = useState("");
  const [selectedDob, setSelectedDob] = useState(null);
  const dateOfBirthField = data?.contactInformation?.contactField?.find(
    (item) => item.code === "date_of_birth"
  );

  const ageLimit = dateOfBirthField?.options[0]?.validation.ageLimit;

  const [isMinAge, setIsMinAge] = useState({
    status: false,
    ageLimit: ageLimit,
  });
  const [isMaxAge, setIsMaxAge] = useState({
    status: false,
    ageLimit: 120,
  });
  const [isCalenderOpen, setIsCalenderOpen] = useState(false);
  const [selectedCountry, setSelectedCountry] = useState("");
  const [selectedState, setSelectedState] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [paymentIntentId, setPaymentIntentId] = useState("");
  const [stripeIntentStatus, setStripeIntentStatus] = useState("");

  const [token, setToken] = useState(null);
  const captchaRef = useRef(null);

  let regFromTree = 1;
  // ---------------------- watch the values of the Register Form --------------------------
  const formValues = watch();
  const regAmount = useSelector(
    (state) => state?.dashboard?.appLayout?.configuration?.regAmount
  );
  const conversionFactor = useSelector(
    (state) => state?.user?.conversionFactor
  );
  const sponsorImage = useSelector(
    (state) => state.dashboard?.appLayout?.user?.image
  );
  const { t } = useTranslation();
  const steps = data?.registrationSteps;

  // -------------------------------------- API ------------------------------------------------
  const registerMutation = ApiHook.CallShopRegisterUser(paymentIntentId);
  const countries = ApiHook.CallGetCountries();
  const countryStates = ApiHook.CallGetStates();

  //----------------------Api call for field value check-----------------------------------
  const checkUsernameField = ApiHook.CallShopRegisterFieldsCheck();
  const checkEmailField = ApiHook.CallShopRegisterFieldsCheck();

  //-------------------------- Form Navigation -------------------------
  const genderOptions = (data) => {
    return data?.map((item) => ({
      value: item.value,
      label: t(item.title),
    }));
  };

  const handleShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const countryList = (data) => {
    return data?.map((item) => ({
      label: item?.name,
      value: item?.id,
    }));
  };

  const stateList = (data) => {
    if (!formValues?.country) {
      return []; // Return an empty array if there's no country specified.
    }

    const selectedCountry = data?.find(
      (country) => country.id === Number(formValues.country)
    );

    if (!selectedCountry) {
      return []; // Return an empty array if the selected country is not found.
    }

    return selectedCountry.States.map((state) => ({
      value: state.id,
      label: state.name,
    }));
  };

  const genderChange = (gender) => {
    setSelectedGender(gender);
    setValue("gender", gender?.value);
  };

  const changeCountry = (country) => {
    setSelectedCountry(country?.value);
    setSelectedState(null); // Clear the selected state
    setValue("state", null);
    setValue("country", country?.value); // Update the form value for country
  };

  const changeState = (state) => {
    setSelectedState(state?.value);
    setValue("state", state?.value);
  };

  const handleNext = async () => {
    let isValid;

    if (activeStep === 2 && formValues.position == null) {
      isValid = false;
      setError("position", { message: t("this_field_is_required") });
    }

    if (activeStep === 3 && token == null) {
      isValid = false;
      setError("token", { message: t("this_field_is_required") });
    }
    if (
      activeStep === 3 &&
      !checkUsernameField.data?.data?.status &&
      checkUsernameField.data?.data?.field === "username" &&
      checkUsernameField.data?.data?.code === 1117
    ) {
      isValid = false;
    } else if (
      activeStep === 2 &&
      !checkEmailField.data?.data?.status &&
      checkEmailField.data?.data?.field === "email" &&
      checkEmailField.data?.data?.code === 1117
    ) {
      isValid = false;
    } else {
      isValid = await trigger();
    }

    if (!formValues?.mobile && activeStep === 2) {
      isValid = false;
      setError("mobile", { message: t("this_field_is_required") });
    }

    if (formValues?.password?.includes(" ") && activeStep === 3) {
      isValid = false;
      setError("password", { message: "password_cannot_contain_white_spaces" });
    }
    if (!isPhoneNumberValid && activeStep === 2) {
      isValid = false;
      setError("mobile", {
        message: t("min_length"),
      });
    }
    if (dateOfBirthField && !formValues?.date_of_birth && activeStep === 2) {
      isValid = setError("date_of_birth", {
        message: t("this_field_is_required"),
      });
    }
    if (!isMinAge?.status && isMinAge?.ageLimit && activeStep === 2) {
      isValid = setError("date_of_birth", {
        message: t("ageValidation", { age: isMinAge?.ageLimit }),
      });
    } else if (isMaxAge?.status && isMaxAge?.ageLimit && activeStep === 2) {
      isValid = setError("date_of_birth", {
        message: t("maxAgeLimit", { age: isMaxAge?.ageLimit }),
      });
    }
    //name validation
    if (activeStep === 2 && formValues.first_name?.length > 15) {
      isValid = false;
      setError("first_name", {
      message: t("max_length_is"),
      });
    }

    if (activeStep === 2 && /[^a-zA-Z\s]/.test(formValues.first_name)) {
      isValid = false;
      setError("first_name", {
      message: t("no_special_characters"),
      });
    }

    if (activeStep === 2 && formValues.last_name?.length > 15) {
      isValid = false;
      setError("last_name", {
      message: t("max_length_is"),
      });
    }

    if (activeStep === 2 && /[^a-zA-Z\s]/.test(formValues.last_name)) {
      isValid = false;
      setError("last_name", {
      message: t("no_special_characters"),
      });
    }
    if (isValid) {
      const nextStep = activeStep + 1;
      animateStepTransition(nextStep);
      setActiveStep(nextStep);
    }
  };
  const handleBack = () => {
    if (paymentIntentId !== "") {
      const body = {
        stripeStatus: stripeIntentStatus,
        paymentIntentId: paymentIntentId,
      };
    }

    const prevStep = activeStep - 1;
    // below step is to clear the uploaded image & value
    if (activeStep === 4 || activeStep === 5) {
      setValue("bankReceipt", undefined);
    }
    animateStepTransition(prevStep);
    setActiveStep(prevStep);
    setSubmitButtonActive(true);
  };

  const handleSubmit = async (paymentId = null, paymentIntent, paymentType) => {
    setSubmitButtonActive(true);
    const isValid = await trigger();

    formValues.token = token;
    if (isValid) {
      if (placement) {
        setValue("placement", placement);
        formValues.placement = placement;
      } else {
        setValue("placement", data?.sponsorData?.username);
        formValues.placement = data?.sponsorData?.username;
      }
      const payload = removeCustomFields(
        formValues,
        data?.contactInformation?.customField
      );

      payload.regFromTree = 0;
      registerMutation.mutate(payload, {
        onSuccess: (res) => {
          console.log(res, "res");
          setSubmitButtonActive(false);
        },
      });
    }
  };

  const handleCustomField = (id, value) => {
    let custom = { ...formValues.customFields };
    custom[id] = value;
    setValue("customFields", custom);
  };

  const openCalender = () => {
    setIsCalenderOpen(true);
  };

  const closeCalender = () => {
    setIsCalenderOpen(false);
  };

  const handleDateChange = (newDate, item) => {
    if (newDate) {
      setIsMinAge({
        status: minAgeValidation(
          newDate,
          item.options[0]?.validation?.ageLimit
        ),
        ageLimit: item.options[0]?.validation?.ageLimit,
      });
      setIsMaxAge({
        status: maxAgeValidation(newDate, 120),
        ageLimit: 120,
      });
      setSelectedDob(newDate);
      const formattedDate = newDate.format("YYYY-MM-DD");
      setValue("date_of_birth", formattedDate);
      setError("date_of_birth", { message: "" });
    }
  };
  // -------------------------- validation Fn's----------------------------------------------
  const minAgeValidation = (selectedDate, ageLimit) => {
    if (selectedDate) {
      const today = dayjs();
      const minAge = today.subtract(ageLimit, "year"); // Calculate the minimum Age
      return selectedDate.isBefore(minAge);
    } else {
      // Handle the case when selectedDate is null or undefined
      return false; // Or you can throw an error or handle it differently
    }
  };

  const maxAgeValidation = (selectedDate, ageLimit) => {
    if (selectedDate) {
      const today = dayjs();
      const maxAge = today.subtract(ageLimit, "year"); // Calculate the max Age
      return !selectedDate.isAfter(maxAge);
    } else {
      return false;
    }
  };

  const handleUsernameCheck = async (item) => {
    const { name, value } = item;
    checkUsernameField.mutate({ field: name, value: value });
  };

  const handleEmailCheck = async (item) => {
    const { name, value } = item;
    checkEmailField.mutate({ field: name, value: value });
  };

  const handleCountry = (selectedCountry, statesData) => {
    if (selectedCountry) {
      setValue("country", selectedCountry);
      formValues.country = selectedCountry;
    }
    statesData?.map((value) => {
      if (parseInt(selectedCountry) === value.id) {
        setStates(value.States);
      }
    });
  };

  const handlePhoneNumber = (phone) => {
    setPhoneNumber(phone);
    setValue("mobile", phone);
    setError("mobile", { message: "" });
  };
  const isPhoneValid = (phone) => {
    // international phoneUtil validation is commented
    // return phoneUtil.isValidNumber(phoneUtil.parseAndKeepRawInput(phone));

    // Add minimum length validation
    return phone.length >= 7 && !isNaN(phone);
  };
  const isPhoneNumberValid = isPhoneValid(phoneNumber);

  const removeCustomFields = (payload, customField) => {
    customField.forEach((field) => {
      const key = field.code;
      delete payload[key];
    });
    return payload;
  };

  useEffect(() => {
    setValue("paymentType", String(3));
  }, [data]);

  useEffect(() => {
    if (position === undefined) {
      setValue("position", null);
    } else {
      setValue("position", String(position));
    }
  }, [position]);

  useEffect(() => {
    if (data?.regPack && data?.regPack.length === 1) {
      const { id, name, pairValue, price } = data?.regPack[0];
      if (regAmount !== undefined) {
        const totalAmount = Number(price) + JSON.parse(regAmount);
        setValue(
          "totalAmt",
          `${CurrencyConverter(totalAmount, conversionFactor)}`
        );
        setValue("totalAmount", totalAmount.toFixed(2));
      }
      setValue(
        "product",
        { id: id, name: name, price: price },
        { shouldValidate: true }
      );
      setValue("pv", pairValue);
      setValue("regAmount", regAmount);
    }
  }, [data?.regPack]);
  // console.log(errors, "errors-----");
  // console.log("====", formValues);

  const onLoad = () => {
    // captchaRef.current.execute();
  };

  useEffect(() => {
    if (token) console.log(`hCaptcha Token: ${token}`);
  }, [token]);


  const step2Deps = { data, activeStep, errors, register, trigger, checkEmailField, handleEmailCheck, selectedDob, handleDateChange, isCalenderOpen,
    openCalender, closeCalender, phoneNumber, handlePhoneNumber, genderOptions, genderChange, selectedGender, selectedCountry, changeCountry,
    selectedState, changeState, handleCustomField, states, handleBack, handleNext,  countryList, stateList, handleCountry };

  const step3Deps = { data, activeStep, checkUsernameField, errors, register, handleUsernameCheck, trigger, handleShowPassword, watch, getValues,
    handleToggleRegisterModal, formValues, onLoad, setToken, captchaRef, handleBack, handleNext, showPassword }

  const step4Deps = { data, countries, countryStates, formValues, handleBack, handleSubmit };

  return (
    <div className="main-content-regsiter">
      <ShopRegisterFormWizardRow steps={steps} activeStep={activeStep} data={data}></ShopRegisterFormWizardRow>
      <div id="animation">
        {activeStep === 2 && (<ShopRegisterFormStep2 {...step2Deps}></ShopRegisterFormStep2>)}
        {activeStep === 3 && (<ShopRegisterFormStep3 {...step3Deps}></ShopRegisterFormStep3>)}
        { activeStep === 4 && (<ShopRegisterFormStep4 {...step4Deps}></ShopRegisterFormStep4>) }
      </div>
      {registerMutation.status === "loading" && (<ShopRegisterFormLoader></ShopRegisterFormLoader>)}
    </div>
  );
};

export default ShopRegisterForm;
