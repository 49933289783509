import React from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import CurrencyConverter from "../../../Currency/CurrencyConverter";
import { WalletChart } from './WalletChart';
import { formatMoney } from '../../../utils/helpers.ts';

export const WalletTiles = ({ wallet, tiles, currency, conversionFactor }) => {
  const { t } = useTranslation();
  const moduleStatus = useSelector(
    (state) => state.dashboard?.appLayout?.moduleStatus
  );

  return (
    <div className="ewallet_top_cnt_sction">
      <div className="row">
        <div className="col-md-4 ">
          <div className="ewallet_top_qc_balance_box">
            <div className="row align-items-center">
              <div className="ewallet_top_qc_balance_box_cnt">
                <div className="ewallet_top_qc_balance_box_cnt_ico">
                  <img src={"/images/ewallet-ico2.png"} alt="" />
                </div>
                <div className="ewallet_top_qc_balance_box_cnt_cnt">
                  <div className="ewallet_top_qc_balance_box_cnt_head">
                    {`${t(wallet)} ${t("balance")}`}
                  </div>
                  <div className="box_amnt_dv">
                    <div className="ewallet_top_qc_balance_box_cnt_val ewallet_top_vl">
                      {`${t(wallet)} ${formatMoney(tiles?.ewalletBalance)}`}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="col-md-4 ">
          <div className="ewallet_top_qc_balance_box">
            <div className="row align-items-center">
              <div className="ewallet_top_qc_balance_box_cnt">
                <div className="ewallet_top_qc_balance_box_cnt_ico">
                  <img src={"/images/approved-ico.svg"} alt="" />
                </div>
                <div className="ewallet_top_qc_balance_box_cnt_cnt">
                  <div className="ewallet_top_qc_balance_box_cnt_head">
                    {t("creditedAmount")}
                  </div>
                  <div className="box_amnt_dv">
                    <div className="ewallet_top_qc_balance_box_cnt_val ewallet_top_vl">
                      {`${t(wallet)} ${formatMoney(tiles?.creditedAmount)}`}
                    </div>
                    <div className="ewallt_top_comparison">
                      {t("lastMonth")}
                      <span
                        className={
                          tiles?.creditSign === "up"
                            ? "cmprson_up"
                            : "cmprson_down"
                        }
                      >
                        {`${tiles?.creditSign === "up" ? "+" : "-"}${
                          tiles?.creditPercentage ?? "0"
                        }% `}
                        <strong>
                          <i
                            className={
                              tiles?.creditSign === "up"
                                ? "fa fa-arrow-up"
                                : "fa fa-arrow-down"
                            }
                          ></i>
                        </strong>
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-md-4 ">
          <div className="ewallet_top_qc_balance_box">
            <div className="row align-items-center">
              <div className="ewallet_top_qc_balance_box_cnt">
                <div className="ewallet_top_qc_balance_box_cnt_ico">
                  <img src={"/images/paid-ico.svg"} alt="" />
                </div>
                <div className="ewallet_top_qc_balance_box_cnt_cnt">
                  <div className="ewallet_top_qc_balance_box_cnt_head">
                    {t("debitedAmount")}
                  </div>
                  <div className="box_amnt_dv">
                    <div className="ewallet_top_qc_balance_box_cnt_val ewallet_top_vl">
                      {`${t(wallet)} ${formatMoney(tiles?.debitedAmount)}`}
                    </div>
                    <div className="ewallt_top_comparison">
                      {t("lastMonth")}
                      <span
                        className={
                          tiles?.debitSign === "up"
                            ? "cmprson_up"
                            : "cmprson_down"
                        }
                      >
                        {`${tiles?.debitSign === "up" ? "+" : "-"}${
                          tiles?.debitPercentage ?? "0"
                        }% `}
                        <strong>
                          <i
                            className={
                              tiles?.debitSign === "up"
                                ? "fa fa-arrow-up"
                                : "fa fa-arrow-down"
                            }
                          ></i>
                        </strong>
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
