import React from 'react';
import { useTranslation } from "react-i18next";

export const ShopRegisterFormStep4 = ({data, countries, countryStates, formValues, handleBack, handleSubmit}) => {
    const {t} = useTranslation();

    return <div className="row row_top justify-content-center form-business">
        <div className="regsiter_second_step_bx">
            <div className="regsiter_overview_box">
                <div className="regsiter_step_1_view_left_sec_head">
                    <strong>{t(`${data?.registrationSteps[1]?.label}`)}</strong>
                </div>

                <div className="row">
                    {(Object.entries(formValues) || [])?.map(([key, value]) => {
                        if (
                            ![
                                "product",
                                "PV",
                                "regAmount",
                                "termsAndCondition",
                                "totalAmt",
                                "paymentType",
                                "customFields",
                                "bankReceipt",
                                "referralId",
                                "totalAmount",
                                "epins",
                                "regAmount",
                                "transactionPassword",
                                "totalEpinAmt",
                                "username",
                                "password",
                                "pv",
                                "confirmPassword",
                                "noProduct",
                                "position",
                            ].includes(key)
                        ) {
                            if (
                                (key === "country" || key === "state") &&
                                value !== undefined &&
                                value !== null
                            ) {
                                return (
                                    <div
                                        className="col-md-4 mb-3 regsiter_overview_col"
                                        key={key}
                                    >
                                        <label htmlFor={`input-${key}`}>{t(key)}</label>
                                        <strong id={`input-${key}`}>
                                            {key === "country" &&
                                                countries.data.find(
                                                    (country) => country.value === value
                                                )?.label}
                                            {key === "state" &&
                                                countryStates.data.find(
                                                    (state) => state.value === value
                                                )?.label}
                                        </strong>
                                    </div>
                                );
                            } else if (key !== "country" && key !== "state") {
                                return (
                                    <div
                                        className="col-md-4 mb-3 regsiter_overview_col"
                                        key={key}
                                    >
                                        <label htmlFor={`input-${key}`}>{t(key)}</label>
                                        <strong id={`input-${key}`}>{value}</strong>
                                    </div>
                                );
                            }
                        }
                        return null; // Return null for keys in the exclusion list or when country/state value is null/undefined
                    })}
                    {formValues?.customField?.map(([key, value]) => (
                        <div className="col-md-4 mb-3 regsiter_overview_col">
                            <label htmlFor={`input-${key}`}>{t(key)}</label>
                            <strong id={`input-${key}`}>{value}</strong>
                        </div>
                    ))}
                </div>

                <div className="regsiter_step_1_view_left_sec_head">
                    <strong>{t(`${data?.registrationSteps[2]?.label}`)}</strong>
                </div>

                <div className="row">
                    <div className="col-md-4 mb-3 regsiter_overview_col">
                        <label htmlFor="username">{t("username")}</label>
                        <strong id="username">{formValues.username}</strong>
                    </div>
                    <div className="col-md-4 mb-3 regsiter_overview_col">
                        <label htmlFor="password">{t("password")}</label>
                        <strong id="password">********</strong>
                    </div>
                </div>

                <div className="col-md-12">
                    <button
                        type="button"
                        className="btn btn-dark text-white float-start back mt-4 rounded-3 bg-color-back"
                        onClick={handleBack}
                    >
                        {t("back")}
                    </button>
                    <button
                        type="button"
                        className="btn text-white float-end next mt-4 rounded-3 bg-color-info"
                        // disabled={submitButtonActive}
                        disabled={false}
                        onClick={handleSubmit}
                    >
                        {t("finish")}
                    </button>
                </div>
            </div>
        </div>
    </div>
}
