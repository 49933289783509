import React from "react";
import { ApiHook } from "../../hooks/apiHook";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import CurrencyConverter from "../../Currency/CurrencyConverter";
import { useQueryClient } from "@tanstack/react-query";
import { useNavigate } from "react-router";

const CheckoutPackage = ({ items, totalAmount, handleNext }) => {
  const { t } = useTranslation();
  const queryClient = useQueryClient();
  const navigate = useNavigate();

  const addCartMutation = ApiHook.CallAddToCart();
  const decrementCartMutation = ApiHook.CallDecrementCartItem();
  const removeCartMutation = ApiHook.CallRemoveCartItem();

  const userSelectedCurrency = useSelector(
    (state) => state.user?.selectedCurrency
  );
  const conversionFactor = useSelector(
    (state) => state?.user?.conversionFactor
  );

  if (items?.length === 0) {
    navigate("/shopping");
  }

  const handleQuantityChange = (event, packageId) => {
    if (event.target.id === "plus") {
      addCartMutation.mutate(
        { packageId: packageId },
        {
          onSuccess: (res) => {
            if (res.status) {
              queryClient.invalidateQueries({ queryKey: ["cart-items"] });
            }
          },
        }
      );
    } else if (event.target.id === "minus") {
      decrementCartMutation.mutate(
        { packageId: packageId },
        {
          onSuccess: (res) => {
            if (res.status) {
              queryClient.invalidateQueries({ queryKey: ["cart-items"] });
            } else {
              queryClient.invalidateQueries({ queryKey: ["cart-items"] });
            }
          },
        }
      );
    }
  };

  return (
    <div className="w-full max-w-2xl">
      {items?.map((product, index) => (
        <div
          key={index}
          className="border-1 border-gray-200 p-2 flex flex-row justify-between items-center rounded-md"
        >
          <div className="flex flex-row gap-2 items-center ">
            <img
              src={product.image ?? "/images/product2.jpg"}
              alt=""
              width={72}
              height={72}
              style={{
                objectFit: "contain",
              }}
            />
            <div>
              <strong>{product.name}</strong>
            </div>
          </div>
          <div className="flex flex-row gap-4 items-center">
            <div className="p-1 bg-gray-100 flex flex-row gap-2 items-center rounded-md max-w-[65px]">
              <button
                id="minus"
                onClick={(e) => handleQuantityChange(e, product?.packageId)}
              >
                <i id="minus" className="fa fa-minus text-sm"></i>
              </button>
              <input
                className="checkout_cnt_qty_input text-sm"
                type="text"
                value={product.quantity}
                onChange={(e) => handleQuantityChange(e, product?.packageId)}
              />
              <button
                id="plus"
                onClick={(e) => handleQuantityChange(e, product?.packageId)}
              >
                <i id="plus" className="fa fa-plus text-sm"></i>
              </button>
            </div>
            <span>
              {`${userSelectedCurrency.symbolLeft} ${CurrencyConverter(
                product.price * product.quantity,
                conversionFactor
              )}`}
              {/* {product.subscriptionDuration ? "/month" : ""} */}
            </span>
          </div>
        </div>
      ))}
      <div className="flex flex-row gap-2 mt-2">
        <button
          className="btn p-2 text-white btn-primary w-full "
          onClick={handleNext}
        >
          {t("checkout")}
        </button>

        <button
          className="btn !text-gray-400 !bg-gray-200 max-w-[150px] w-full"
          onClick={() => navigate("/shopping")}
        >
          {t("cancel")}
        </button>
      </div>
    </div>
  );
};

export default CheckoutPackage;
