import React, { useState, useEffect, useMemo } from "react";
import { Offcanvas } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { reverseNumberDisplay } from '../../utils/currencyNumberDisplay';
import CurrencyConverter from '../../Currency/CurrencyConverter';
import { toast } from 'react-toastify';
import { useSelector } from 'react-redux';
import { useQueryClient } from '@tanstack/react-query';
import { ApiHook } from '../../hooks/apiHook';
import { useTopupPolling } from '../../contexts/TopupPollingContext';

const disclaimer = "Only deposit USDT from the TRON network (TRC20). Deposits from other networks will not be credited and cannot be recovered";


const UsdkFundTopUp = ({ balance, show, handleClose, currency }) => {
  const { t } = useTranslation();
  const [transferData, setTransferData] = useState({
    amount: "",
    transactionPassword: "no-need-for-password",
  });
  const conversionFactor = useSelector(
      (state) => state?.user?.conversionFactor
  );
  const defaultCurrencyJson = useSelector(
      (state) => state?.user?.loginResponse?.defaultCurrency
  );

  const { startTopupPolling } = useTopupPolling();



  const defaultCurrency = defaultCurrencyJson
      ? JSON.parse(defaultCurrencyJson)
      : null;
  const queryClient = useQueryClient();
  const [errorMessage, setErrorMessage] = useState({
    amount: null,
    transactionPassword: null,
  });

  const [qrCode, setQrCode] = useState(null);
  const [walletAddress, setWalletAddress] = useState(null);
  const [error, setError] = useState(null);

  const userData = useSelector((state)=> state.user?.loginResponse?.user)
  const user = useMemo(() => JSON.parse(userData || "{}"), [userData]);

  const { mutateAsync: generateQrCode, isLoading } = ApiHook.CallGenerateUserTopUpQR();

  const fetchQrCode = async () => {
    try {
      const response = await generateQrCode();
      setQrCode(response.data.qrCode); // Assuming the response contains the `qrCode` field
      setWalletAddress(response.data.address); // Assuming the response contains the `qrCode` field
    } catch (err) {
      setError('Failed to load QR code');
    }
  };

  useEffect(() => {
    if (user?.id && !qrCode) {
      fetchQrCode();
    }
  }, [user]);

  const transferMutation = ApiHook.CallUsdkFundTopup();

  const isFormValid = () => {
    return (
        transferData?.amount > 0
    );
  };

  const handleCheckTopup = async (e) => {
    e.preventDefault();
    localStorage.setItem(`checkTopup`, true);
    startTopupPolling();
    handleClose();
  }

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!isFormValid()) {
      console.log('form not valid', transferData);
      return;
    }
    try {
      let convertAmount;
      if (currency.id === defaultCurrency.id) {
        convertAmount = reverseNumberDisplay(
            CurrencyConverter(transferData?.amount, conversionFactor, 0)
        );
      } else {
        convertAmount = reverseNumberDisplay(
            CurrencyConverter(transferData?.amount, conversionFactor, 1)
        );
      }
      const postData = {
        username: transferData?.username,
        amount: convertAmount,
        transactionPassword: transferData?.transactionPassword,
      };
      await transferMutation.mutateAsync(postData, {
        onSuccess: (res) => {
          if (res.status === 200) {
            setErrorMessage({
              username: null,
              transactionPassword: null,
            });
            setTransferData({
              amount: "",
              transactionPassword: "",
            });
            queryClient.invalidateQueries({
              queryKey: ["usdk-statement"],
            });
            queryClient.invalidateQueries({
              queryKey: ["usdkwallet-tiles"],
            });
            queryClient.invalidateQueries({
              queryKey: ["usdk-transfer-history"],
            });
            handleClose(false);
          } else {
            console.log(res?.data, "code");
            if (res?.data?.code) {
              toast.error(res?.data?.description);
            }
            if (res?.data?.data?.code === 1011) {
              setErrorMessage((prevErrors) => ({
                ...prevErrors,
                username: t(res?.data?.data?.description),
              }));
            } else if (res?.data?.data?.code === 1015) {
              setErrorMessage((prevErrors) => ({
                ...prevErrors,
                transactionPassword: t(res?.data?.data?.description),
              }));
            } else if (res?.data?.data?.code === 1014) {
              setErrorMessage((prevErrors) => ({
                ...prevErrors,
                amount: t(res?.data?.data?.description),
              }));
            } else if (res?.data?.data?.code === 1088) {
              setErrorMessage((prevErrors) => ({
                ...prevErrors,
                username: t(res?.data?.data?.description),
              }));
            } else if (res?.data?.data?.code) {
              toast.error(res?.data?.data?.description);
            } else {
              toast.error(res?.message);
            }
          }
        },
      });
    } catch (error) {
      // Handle general error or network issue
      console.error("Error submitting transfer:", error);
    }
  };

  const handleCopy = () => {
    navigator.clipboard.writeText(walletAddress);
  }

  return (
    <Offcanvas
      show={show}
      onHide={() => { handleClose(); }}
      placement="end"
      id="usdkFundTopUp"
    >
      <Offcanvas.Header closeButton>
        <Offcanvas.Title>{t("usdkFundTopUp")}</Offcanvas.Title>
      </Offcanvas.Header>
      <Offcanvas.Body>
        <div className="disclaimer-box">
          <h3 className="disclaimer-title">Disclaimer</h3>
          <p className="disclaimer-text">
            {disclaimer}
          </p>
        </div>

        <div className="disclaimer-box !bg-yellow-100 flex flex-row justify-between">
          <h3 className="disclaimer-title">Transaction Fee:</h3>
          <h3 className="disclaimer-title">1.5%</h3>
        </div>
        {/*<Form.Group className="mb-3">*/}
        {/*  <Form.Label>*/}
        {/*    {t("amount")}*/}
        {/*    <span>*</span>*/}
        {/*  </Form.Label>*/}
        {/*  <div className="amount-field input-group">*/}
        {/*    <CurrencyInput*/}
        {/*        name="amount"*/}
        {/*        id="amount"*/}
        {/*        placeholder={t("amount")}*/}
        {/*        value={transferData.amount}*/}
        {/*        onValueChange={(value, id) =>*/}
        {/*            handleChange({target: {value, id}})*/}
        {/*        }*/}
        {/*        required*/}
        {/*        allowNegativeValue={false}*/}
        {/*        decimalsLimit={2}*/}
        {/*    />*/}
        {/*  </div>*/}
        {/*</Form.Group>*/}

        <div className="deposit-address-container">
          <p className="deposit-title">USDT - Deposit Address</p>
          <p className="deposit-subtitle">Scan QR Code</p>
          <div className="flex items-center justify-center" >
            {qrCode ? (
                <img src={qrCode} alt="QR Code" className={`qr-code-image`}/>
            ) : (
                <p>Loading QR Code...</p>
            )}
          </div>
        </div>

        <div className="address-container mb-10">
          <div className="address">
            <label className="address-label">{walletAddress}</label>
          </div>
          <button className="copy-button" onClick={handleCopy}>Copy</button>
        </div>

        <button
            className={`btn btn-primary submit_btn`}
            type="submit"
            onClick={handleCheckTopup}
        >
          Done
        </button>


      </Offcanvas.Body>
    </Offcanvas>
  );
};

export { UsdkFundTopUp };
