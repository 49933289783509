const config = {
    ENV: process.env.REACT_APP_ENV,
    BASE_URL: process.env.REACT_APP_BASE_URL,
    BASE_URL_DOMAIN: process.env.REACT_APP_BASE_URL_DOMAIN,
    BASE_URL_IP: process.env.REACT_APP_BASE_URL_IP,
    DEFAULT_KEY: process.env.REACT_APP_DEFAULT_KEY,
    PAYPAL_CLIENT_ID: process.env.REACT_APP_PAYPAL_CLIENT_ID,
    SITEKEY: process.env.REACT_APP_SITEKEY,
    TOPUP_POLL_INTERVAL: process.env.REACT_APP_TOPUP_POLL_INTERVAL,
    TOPUP_POLL_MAX_TIME: process.env.REACT_APP_TOPUP_POLL_MAX_TIME,
    CAPTCHA_SITEKEY: process.env.REACT_APP_CAPTCHA_SITEKEY,
};

// Check if the hostname is an IP address
const isIPAddress = (hostname) => {
    const ipRegex = /^(25[0-5]|2[0-4][0-9]|[0-1]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[0-1]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[0-1]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[0-1]?[0-9][0-9]?)$/;
    return ipRegex.test(hostname);
};

const BASE_URL = config.ENV === 'local' ? config.BASE_URL :
    isIPAddress(window.location.hostname) ? config.BASE_URL_IP : config.BASE_URL_DOMAIN;

export const { DEFAULT_KEY, PAYPAL_CLIENT_ID, SITEKEY, CAPTCHA_SITEKEY, TOPUP_POLL_INTERVAL, TOPUP_POLL_MAX_TIME } = config;
export { BASE_URL };

