import React, { useState } from "react";
import { Link, NavLink, useLocation, useNavigate } from "react-router-dom";
import { Popover, OverlayTrigger } from "react-bootstrap";
import { ApiHook } from "../../hooks/apiHook";
import { useTranslation } from "react-i18next";
import menuPlaceHolder from "../../examples/dashboardMenu.json";
const LeftSection = ({
  isLeftMenuAlwaysVisible,
  isLeftMenuVisible,
  handleLeftMenuToggle,
  menus,
  spclMenu,
  toggleMenuRef,
  logo,
}) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [activeSubMenuIndex, setActiveSubMenuIndex] = useState(-1);
  const [registerLinkCheck, setRegisterLinkCheck] = useState(false);
  const [storeLinkCheck, setStoreLinkCheck] = useState(false);
  const location = useLocation();
  const menuItems = menuPlaceHolder;

  const handleDropMenuClick = (index) => {
    if (activeSubMenuIndex === index) {
      setActiveSubMenuIndex(-1); // Close the submenu if it's already open
    } else {
      setActiveSubMenuIndex(index); // Open the clicked submenu
    }
  };
  const renderPopover = (
    content // popover the tilte in Menu
  ) => (
    <Popover>
      <Popover.Body>{content}</Popover.Body>
    </Popover>
  );
  const islinkActive = (link) => {
    if (link === "/balances") {
      return location.pathname === "/usdk" ||
        location.pathname === "/usdk-promo" ||
        location.pathname === "/usdk-wallet" ||
        location.pathname === "/usdkc"
        ? "active"
        : "";
    }
    if (link === "/networks") {
      return location.pathname === "/sponsor-tree" ||
        location.pathname === "/genealogy-tree" ||
        location.pathname === "/tree-view" ||
        location.pathname === "/downline-members" ||
        location.pathname === "/referral-members"
        ? "active"
        : "";
    } else if (location.pathname === link) {
      return "active";
    }
  };
  // ----------------------------- Api Call for Ecom Link ----------------------
  const registerLink = ApiHook.CallRegisterLink(
    registerLinkCheck,
    setRegisterLinkCheck
  );
  if (registerLink.isFetched) {
    window.location.href = registerLink.data?.link;
  }
  const storeLink = ApiHook.CallStoreLink(storeLinkCheck, setStoreLinkCheck);
  if (storeLink.isFetched) {
    window.location.href = storeLink.data?.link;
  }
  return (
    <aside className="left_sidebar">
      <nav
        className="left_navigation_section"
        data-tut="side-navbar"
        id="tuto2"
      >
        <ul className="left_navigation">
          {menuItems?.map((menuItem, index) => {
            return (
              <li key={index}>
                <OverlayTrigger
                  key={menuItem.slug}
                  trigger={["hover", "focus"]}
                  placement="right"
                  overlay={renderPopover(t(`${menuItem.slug}`))}
                >
                  <NavLink
                    className={islinkActive(`/${menuItem.slug}`)}
                    to={menuItem.slug}
                  >
                    <i>
                      <img src={`/images/${menuItem.userIcon}`} alt="" />
                    </i>
                    <span>{menuItem.title}</span>
                  </NavLink>
                </OverlayTrigger>
              </li>
            );
          })}
        </ul>
      </nav>
    </aside>
  );
};

export default LeftSection;
